export class Stats {
    numPraticheAnno: number = 0;
    numPraticheTot: number = 0;
    numPraticheDaGestire: number = 0;
    ferme: StatsFermeAnno[] = [];
    stati: StatsStatoPraticheAnno[] = [];
    statiAgenzie: StatsStatiAgenzia[] = [];
    fermeAgenzie: StatsFermeAgenzia[] = [];
}

export class StatsFermeAnno {
    anno: number = 0;
    nonSpecificato: number = 0;
    rifiutoDelCliente: number = 0;
    agenziaNoContattoCliente: number = 0;
    insanabile: number = 0;
    irraggiungibile: number = 0;
    interventoAltroTecnico: number = 0;
    mancanzaDocumentiAgenzia: number = 0;
    mancanzaNumeroProprietario: number = 0;
    attesaRispostaCliente: number = 0;
    attesaRispostaAgente: number = 0;
    doppia: number = 0;
}

export class StatsStatoPraticheAnno {
    anno: number = 0;
    inLavorazione: number = 0;
    conclusa: number = 0;
    rogitata: number = 0;
    ferma: number = 0;
    nuova: number = 0;
    annullata: number = 0;
}

export class StatsStatiAgenzia {
    agenzia: string = "";
    inLavorazione: number = 0;
    concluse: number = 0;
    rogitate: number = 0;
    ferme: number = 0;
    nuove: number = 0;
}

export class StatsFermeAgenzia {
    agenzia: string = "";
    numero: number = 0;
    motivo: string = "";
}