import { StatiPratica } from "./const/pratica-const";

export abstract class Utils {
    
  public static parseDataString(dataString: string) : Date | null {
      var splitted = dataString.split("/");
      if(splitted.length != 3) {
        return null;
      }
      if(splitted[0].length != 2) {
        return null;
      }
      if(splitted[1].length != 2) {
        return null;
      }
      if(splitted[2].length != 4) {
        return null;
      }
      var parsed0 = parseInt(splitted[0]);
      var parsed1 = parseInt(splitted[1]);
      var parsed2 = parseInt(splitted[2]);
      if(isNaN(parsed0)) {
        return null;
      }
      if(isNaN(parsed1)) {
        return null;
      }
      if(isNaN(parsed2)) {
        return null;
      }
      if(parsed0 < 1 || parsed0 > 31) {
        return null;
      }
      if(parsed1 < 1 || parsed1 > 12) {
        return null;
      }
      if(parsed2 < 1900 || parsed2 > 2100) {
        return null;
      }
      var date = new Date(parsed2, parsed1-1, parsed0);
      return date;
  }

  public static getStatoString(stato: StatiPratica) : string {
    var result = "";
    switch(stato) {
      case StatiPratica.ANNULLATA:
        result = "Annullata";
        break;
      case StatiPratica.CONCLUSA:
        result = "Conclusa";
        break;
      case StatiPratica.FERMA:
        result = "Ferma";
        break;
      case StatiPratica.IN_LAVORAZIONE:
        result = "In lavorazione";
        break;
      case StatiPratica.NUOVA:
        result = "Nuova";
        break;
      case StatiPratica.ROGITATA:
        result = "Rogitata";
        break;
    }
    return result;
  }
}