import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnagraficaUtente } from '../models/anagrafica-utente';
import { environment } from 'src/environments/environment';
import { CercaTecniciModel } from '../models/cerca-tecnici-model';
import { UtenteSocieta } from '../models/utente-societa';

@Injectable({
  providedIn: 'root'
})
export class UtentiService {

  constructor(private http: HttpClient) { }

  getUtenti() : Promise<AnagraficaUtente[]> {
    return new Promise<AnagraficaUtente[]>((resolve, reject) => {
      this.http.get(environment.API_URL + "utenti").subscribe({next: (res: any) => {
        resolve(res as AnagraficaUtente[]);
      }, error: (err: HttpErrorResponse) => {
        reject(err.error);
      }});
    });
  }

  getUtenteById(id: string) : Promise<AnagraficaUtente> {
    return new Promise<AnagraficaUtente>((resolve, reject) => {
      this.http.get(environment.API_URL + "utenti/" + id).subscribe({next: (res: any) => {
        resolve(res as AnagraficaUtente);
      }, error: (err: HttpErrorResponse) => {
        reject(err.error);
      }});
    });
  }

  createUtente(user: AnagraficaUtente) : Promise<AnagraficaUtente> {
    return new Promise<AnagraficaUtente>((resolve, reject) => {
      this.http.post(environment.API_URL + "utenti", user).subscribe({next: (res: any) => {
        resolve(res as AnagraficaUtente);
      }, error: (err: HttpErrorResponse) => {
        reject(err.error);
      }});
    });
  }

  editUtente(user: AnagraficaUtente) : Promise<AnagraficaUtente> {
    return new Promise<AnagraficaUtente>((resolve, reject) => {
      user.tecnico_albo = Number(user.tecnico_albo);
      user.telefono = user.telefono ?? "";
      this.http.put(environment.API_URL + "utenti", user).subscribe({next: (res: any) => {
        resolve(res as AnagraficaUtente);
      }, error: (err: HttpErrorResponse) => {
        reject(err.error);
      }});
    });
  }

  getTecniciAdmin(model: CercaTecniciModel) : Promise<AnagraficaUtente[]> {
    return new Promise<AnagraficaUtente[]>((resolve, reject) => {
      var query = Object.entries(model).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&');
      this.http.get(environment.API_URL + "utenti/tecnici?" + query).subscribe({next: (res: any) => {
        resolve(res as AnagraficaUtente[]);
      }, error: (err: HttpErrorResponse) => {
        reject(err.error);
      }});
    });
  }

  createTecnicoInterno(model: AnagraficaUtente) : Promise<any> {
    return new Promise<any>((resolve, reject) => {
      model.tecnico_albo = Number(model.tecnico_albo);
      this.http.post(environment.API_URL + "utenti/admin/tecnico", model).subscribe({next: (res: any) => {
        resolve(res);
      }, error: (err: HttpErrorResponse) => {
        reject(err.error);
      }})
    });
  }

  getTeamLeaders() : Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.get(environment.API_URL + "utenti/teamleader").subscribe({next: (res: any) => {
        resolve(res);
      }, error: (err: HttpErrorResponse) => {
        reject(err.error);
      }})
    });
  }

  createTeamLeader(user: AnagraficaUtente) : Promise<AnagraficaUtente> {
    return new Promise<AnagraficaUtente>((resolve, reject) => {
      this.http.post(environment.API_URL + "utenti/teamleader", user).subscribe({next: (res: any) => {
        resolve(res as AnagraficaUtente);
      }, error: (err: HttpErrorResponse) => {
        reject(err.error);
      }});
    });
  }
}
