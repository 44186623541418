import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StatiPratica } from 'src/app/const/pratica-const';
import { AnagraficaSocieta } from 'src/app/models/anagrafica-societa';
import { RegistrazioneSocieta } from 'src/app/models/registrazione-societa';
import { AuthService } from 'src/app/services/auth.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-registrazione-agenzia',
  templateUrl: './registrazione-agenzia.component.html',
  styleUrls: ['./registrazione-agenzia.component.scss']
})
export class RegistrazioneAgenziaComponent {
  public loading: boolean = false;
  public model: RegistrazioneSocieta = new RegistrazioneSocieta();
  public confermaPassword: string = "";

  constructor( private auth: AuthService, private toastr: ToastrService, private router: Router, private spinner: SpinnerService){}

  register() {
    if(!this.validate()) {
      return;
    }
    this.spinner.show();
    this.auth.registrazioneSocieta(this.model).then(() => {
      this.spinner.dismiss();
      this.router.navigate(["/thankyou-registrazione"]);
    }).catch((err: string) => {
      var errore = "Errore sconosciuto";
      switch(err) {
        case "error.user.already_exists":
          errore = "Esiste già un utente con questa mail";
          break;
        case "error.user.password_invalid":
          errore = "La password non è valida";
          break;
        case "error.user.email_invalid":
          errore = "Inserisci un indirizzo email valido";
          break;
        case "error.societa.conflict":
          errore = "Esiste già una società registrata con questa partita IVA";
          break;
        case "piva_conflict":
          errore = "Esiste già una società registrata con questa partita IVA";
          break;
        case "error.user.internal":
          errore = "C'è stato un problema con il salvataggio, contattaci a info@praticaok.com";
          break;
        case "required_missing":
          errore = "Devi compilare tutti i campi richiesti";
          break;
        case "save_error":
          errore = "C'è stato un problema con il salvataggio, contattaci a info@praticaok.com";
          break;
      }
      this.toastr.error(errore);
      this.spinner.dismiss();
    });
  }

  validate() : boolean {
    if(!this.auth.validatePassword(this.model.utente.password)) {
      this.toastr.error("La password deve essere lunga almeno 8 caratteri e contenere almeno una lettera minuscola, una minuscola e un numero");
      return false;
    }
    if(this.model.utente.password != this.confermaPassword) {
      this.toastr.error("Le due password devono coincidere");
      return false;
    }
    return true;
  }
}
