<div class="page-container container-large">
    <h3 class="section-title">Cerca</h3>
    <div class="cerca pt-2 pr-2 pb-0 pl-2">
        <div class="clr-row input-block">
            <ng-container *ngIf="auth.isAdmin || auth.isTeamLeader">
                <div class="clr-col-md">
                    <clr-select-container>
                        <label>Agenzia</label>
                        <select clrSelect [(ngModel)]="cercaModel.cercaAgenzia">
                            <option value="">{{ auth.isAdmin ? "Interne" : "Tutte"}}</option>
                            <option *ngFor="let agenzia of listaSocieta" [value]="agenzia.ragioneSociale">{{agenzia.ragioneSociale}}</option>
                        </select>
                    </clr-select-container>
                </div>
                <div class="clr-col-md" *ngIf="auth.isAdmin">
                    <clr-select-container>
                        <label>Tecnico</label>
                        <select clrSelect [(ngModel)]="cercaModel.cercaTecnico">
                            <option value="">Tutti</option>
                            <option *ngFor="let tec of tecnici" [value]="tec.id">{{tec.nome}}</option>
                        </select>
                    </clr-select-container>
                </div>
            </ng-container>
            <div class="clr-col-md">
                <clr-input-container>
                    <label>Consulente</label>
                    <input clrInput type="text" [(ngModel)]="cercaModel.cercaConsulente"/>
                </clr-input-container>
            </div>
            <div class="clr-col-md">
                <clr-input-container>
                    <label>Proprietario</label>
                    <input clrInput type="text" [(ngModel)]="cercaModel.cercaProprietario"/>
                </clr-input-container>
            </div>
            <div class="clr-col-md">
                <clr-input-container>
                    <label>Comune</label>
                    <input clrInput type="text" [(ngModel)]="cercaModel.cercaComune"/>
                </clr-input-container>
            </div>
            <div class="clr-col-md">
                <clr-input-container>
                    <label>Indirizzo</label>
                    <input clrInput type="text" [(ngModel)]="cercaModel.cercaIndirizzo"/>
                </clr-input-container>
            </div>
        </div>
        <div class="clr-row">
            <div class="clr-col-md-2 clr-col-6">
                <div class="clr-form-control">
                    <div class="clr-control-container">
                        <div class="clr-checkbox-wrapper">
                            <input type="checkbox" clrCheckbox id="cercaStatoInLavorazione" name="cercaStatoInLavorazione" [(ngModel)]="cercaModel.cercaStatoInLavorazione"/>
                            <label for="cercaStatoInLavorazione">In lavorazione</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clr-col-md-2 clr-col-6">
                <div class="clr-form-control">
                    <div class="clr-control-container">
                        <div class="clr-checkbox-wrapper">
                            <input type="checkbox" clrCheckbox id="cercaStatoConclusa" name="cercaStatoConclusa" [(ngModel)]="cercaModel.cercaStatoConclusa"/>
                            <label for="cercaStatoConclusa">Conclusa</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clr-col-md-2 clr-col-6">
                <div class="clr-form-control">
                    <div class="clr-control-container">
                        <div class="clr-checkbox-wrapper">
                            <input type="checkbox" clrCheckbox id="cercaStatoRogitata" name="cercaStatoRogitata" [(ngModel)]="cercaModel.cercaStatoRogitata"/>
                            <label for="cercaStatoRogitata">Rogitata</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clr-col-md-2 clr-col-6">
                <div class="clr-form-control">
                    <div class="clr-control-container">
                        <div class="clr-checkbox-wrapper">
                            <input type="checkbox" clrCheckbox id="cercaStatoFerma" name="cercaStatoFerma" [(ngModel)]="cercaModel.cercaStatoFerma"/>
                            <label for="cercaStatoFerma">Ferma</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clr-col-md-2 clr-col-6">
                <div class="clr-form-control">
                    <div class="clr-control-container">
                        <div class="clr-checkbox-wrapper">
                            <input type="checkbox" clrCheckbox id="cercaStatoNuova" name="cercaStatoNuova" [(ngModel)]="cercaModel.cercaStatoNuova"/>
                            <label for="cercaStatoNuova">Nuova</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-right">
            <button class="btn btn-primary" (click)="cerca()">Cerca</button>
        </div>
    </div>
</div>
<div class="page-container container-large container-pratiche">
    <div class="clr-row">
        <div class="clr-col-md-8">
            <p>
                <span class="stato stato_0"></span> IN LAVORAZIONE &nbsp;
                <span class="stato stato_1"></span> CONCLUSA &nbsp;
                <span class="stato stato_2"></span> ROGITATA &nbsp;
                <span class="stato stato_3"></span> FERMA &nbsp;
                <span class="stato stato_4"></span> NUOVA
            </p>
        </div>
        <div class="clr-col-md-4 text-right">
            <button class="btn btn-primary" (click)="export('xls')"><fa-icon [icon]="faFileExcel"></fa-icon> Esporta</button>
            <button class="btn btn-primary" (click)="export('pdf')"><fa-icon [icon]="faPrint"></fa-icon> Stampa</button>
        </div>
    </div>
    <div>
        <button *ngIf="!auth.isAdmin && !auth.isTecnico" class="btn btn-primary mt-1" (click)="crea()"><fa-icon [icon]="faPlus"></fa-icon> Crea nuova pratica</button>
    </div>

    <div class="paginazione" *ngIf="pagine > 1">
        <pagination-controls class="pagination-controls" (pageChange)="setPage($event)" [previousLabel]="''" [nextLabel]="''"></pagination-controls>
    </div>
    
    <div class="table-container">
        <table class="table pratiche-table">
            <thead>
                <th></th>
                <th></th>
                <th>Num. pratica</th>
                <th *ngIf="auth.isAdmin || auth.isTeamLeader || (auth.isTecnico && auth.isTecnicoInterno)">Agenzia</th>
                <th>Consulente</th>
                <th>Indirizzo</th>
                <th>Proprietà</th>
                <th>Tecnico</th>
                <th>Data inserimento</th>
                <th>Sopraluogo</th>
                <th></th>
            </thead>
            <tbody>
                <tr *ngFor="let pratica of pratiche | paginate: { itemsPerPage: pageSize, currentPage: cercaModel.page, totalItems: pagine*pageSize }; index as i" [ngClass]="{'annullata': pratica.stato == statiPratica.ANNULLATA}">
                    <td><div class="stato stato_{{pratica.stato}}" title="{{pratica.stato == statiPratica.FERMA ? MotiviFermoLabel[pratica.motivoFermoPratica] : ''}}"></div></td>
                    <td><fa-icon [icon]="faTriangleExclamation" *ngIf="pratica.hasLogs" class="logs-warning"></fa-icon></td>
                    <td>{{pratica.numerazione}}</td>
                    <td *ngIf="auth.isAdmin || auth.isTeamLeader || (auth.isTecnico && auth.isTecnicoInterno)"><img src="{{pratica.societa.logo}}" *ngIf="!pratica.societa.errorLoadingLogo" (error)="pratica.societa.errorLoadingLogo = true"/><span *ngIf="pratica.societa.errorLoadingLogo">{{pratica.societa.ragioneSociale}}</span></td>
                    <td>{{pratica.consulente.nome}}</td>
                    <td>
                        {{pratica.indirizzo}}<br/>
                        {{pratica.citta}} {{pratica.provincia.length > 0 ? "(" + pratica.provincia + ")" : ""}}
                        <span *ngIf="pratica.piano.length > 0"><br/>P. {{pratica.piano}}</span>
                        <span *ngIf="pratica.scala.length > 0"><br/>S. {{pratica.scala}}</span>
                    </td>
                    <td>{{pratica.persone.length > 0 ? pratica.persone[0].nome : ""}}</td>
                    <td>{{pratica.tecnico ? pratica.tecnico.nome : ""}}</td>
                    <td>{{pratica.dataCreazione.endsWith("0001") ? "" : pratica.dataCreazione}}</td>
                    <td>{{pratica.sopraluogo ? "SI" : "NO"}}</td>
                    <td>
                        <div class="btn-group btn-primary">
                            <ng-container *ngIf="pratica.stato != this.statiPratica.ANNULLATA || auth.isAdmin">
                                <button class="btn btn-primary btn-icon" (click)="edit(pratica)"><fa-icon [icon]="faPenToSquare"></fa-icon></button>
                            </ng-container>
                            <ng-container *ngIf="pratica.stato != this.statiPratica.ANNULLATA">
                                <button class="btn btn-outline-danger btn-icon" (click)="annulla(pratica.id)"><fa-icon [icon]="faTrashCan"></fa-icon></button>
                            </ng-container>
                            <ng-container *ngIf="auth.isAdmin">
                                <button class="btn btn-danger btn-icon" (click)="elimina(pratica.id)"><fa-icon [icon]="faTrashCan"></fa-icon></button>
                            </ng-container>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<clr-modal [(clrModalOpen)]="showModalAnnullamento">
    <h3 class="modal-title text-center">Confermi di voler cancellare la pratica?</h3>
    <div class="modal-body">
        <div class="input-block">
            <p>Seleziona il motivo della cancellazione</p>
            <clr-select-container>
                <select clrSelect [(ngModel)]="annullaMotivo">
                    <option [value]="MotiviAnnullamentoPratica.SCADUTO_INCARICO">{{MotiviAnnullamentoLabel[MotiviAnnullamentoPratica.SCADUTO_INCARICO]}}</option>
                    <option [value]="MotiviAnnullamentoPratica.IMMOBILE_ROGITATO">{{MotiviAnnullamentoLabel[MotiviAnnullamentoPratica.IMMOBILE_ROGITATO]}}</option>
                    <option [value]="MotiviAnnullamentoPratica.CLIENTE_NON_VUOLE_VENDERE">{{MotiviAnnullamentoLabel[MotiviAnnullamentoPratica.CLIENTE_NON_VUOLE_VENDERE]}}</option>
                    <option [value]="MotiviAnnullamentoPratica.AGENZIA_RESCISSO_MANDATO">{{MotiviAnnullamentoLabel[MotiviAnnullamentoPratica.AGENZIA_RESCISSO_MANDATO]}}</option>
                </select>
            </clr-select-container>
            <div class="mt-2">
                <button type="button" (click)="annullaConfirm()" class="btn btn-primary btn-block">Annulla pratica</button>
            </div>
        </div>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showModalSelectAgenziaCrea">
    <h3 class="modal-title text-center">Seleziona agenzia</h3>
    <div class="modal-body">
        <h4 class="text-align-center">Seleziona l'agenzia per cui vuoi creare la pratica</h4>
        <select clrSelect [(ngModel)]="societaIdSelected">
            <option *ngFor="let agenzia of listaSocieta" [value]="agenzia.id">{{agenzia.ragioneSociale}}</option>
        </select>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="creaDaTeamLeader()">Crea nuova pratica</button>
      </div>
</clr-modal>
