import { TipiPersone } from "../const/pratica-const";
import { Documento } from "./documento";

export class Persona {
    id: number = 0;
    nome: string = "";
    telefono: string = "";
    email: string = "";
    tipoPersona: TipiPersone = TipiPersone.ACQUIRENTE;
    ordine: number = 0;
    praticaId: number = 0;
    documenti: Documento[] = [];
    
    documentiIdentita: Documento[] = [];
    documentiCF: Documento[] = [];
    documentiPrivacy: Documento[] = [];
}