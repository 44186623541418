import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { SpinnerService } from './services/spinner.service';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { UserRoles } from './const/user-roles';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  collapsed: boolean = true;
  showMenuAnagrafica: boolean = false;
  showMenuUtenti: boolean = false;
  showMenuAnagraficaTecnico: boolean = false;

  constructor(public router: Router, public auth: AuthService, public spinner: SpinnerService){
    registerLocaleData(localeIt);
  }
  
  ngOnInit() {
    this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        this.updateMenu();
        window.scrollTo(0,0);
      }
    });
  }

  updateMenu() {
    this.showMenuAnagrafica = this.auth.user?.ruolo == UserRoles.TitolareAgenzia || this.auth.user?.ruolo == UserRoles.ResponsabileAgenzia;
    this.showMenuUtenti = this.auth.user?.ruolo == UserRoles.TitolareAgenzia || this.auth.user?.ruolo == UserRoles.ResponsabileAgenzia;
  }
}
