import { Injectable } from '@angular/core';
import { Pratica } from '../models/pratica';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { CercaPraticheModel } from '../models/cerca-pratiche-model';
import { environment } from 'src/environments/environment';
import { PrepPratica } from '../models/prep-pratica';
import { MessaggioChat } from '../models/messaggio-chat';
import { GetPraticaResult } from '../models/get-pratica-result';
import { PraticheList } from '../models/pratiche-list';
import { Documento } from '../models/documento';
import { Stats } from '../models/stats-model';
import { MotiviAnnullamentoPratica } from '../const/pratica-const';

@Injectable({
  providedIn: 'root'
})
export class PraticheService {

  constructor(private http: HttpClient) { }

  getPratiche(model: CercaPraticheModel) : Promise<PraticheList> {
    return new Promise<PraticheList>((resolve, reject) => {
      var query = Object.entries(model).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&');
      this.http.get(environment.API_URL + "pratiche?" + query).subscribe({next: (res: any) => {
        var pratiche = new Array<Pratica>();
        var result = res as PraticheList;
        result.list.forEach(p => {
          p.societa.logo = environment.API_URL + "loghiSocieta/" + p.societaId + ".png";
        });
        resolve(result);
      }, error: (err: HttpErrorResponse) => {
        reject(err.error);
      }});
    });
  }

  getPreparazionePratica(societaId: number, praticaId: number) : Promise<PrepPratica> {
    return new Promise<PrepPratica>((resolve, reject) => {
      this.http.get(environment.API_URL + "pratiche/prepratica?societaId=" + societaId + "&praticaId=" + praticaId).subscribe({next: (res: any) => {
        var prep = res as PrepPratica;
        resolve(prep);
      }, error: (err: HttpErrorResponse) => {
        reject(err.error);
      }});
    });
  }

  createPratica(pratica: Pratica, files: File[]) : Promise<Pratica> {
    return new Promise((resolve, reject) => {
      var formdata = new FormData();
      formdata.append("body", JSON.stringify(pratica));
      files.forEach((file) => {
        formdata.append(file.name, file, file.name);
      });
      var headers = new HttpHeaders();
      headers.append("Content-Type", "multipart/form-data");
      this.http.post(environment.API_URL + "pratiche", formdata, {headers: headers}).subscribe({next: (res) => {
        resolve(res as Pratica);
      }, error: (err: HttpErrorResponse) => {
        reject(err.error);
      }})
    });
  }

  editPratica(pratica: Pratica, files: File[]) : Promise<Pratica> {
    return new Promise((resolve, reject) => {
      var formdata = new FormData();
      formdata.append("body", JSON.stringify(pratica));
      files.forEach((file) => {
        formdata.append(file.name, file, file.name);
      });
      var headers = new HttpHeaders();
      headers.append("Content-Type", "multipart/form-data");
      this.http.put(environment.API_URL + "pratiche", formdata, {headers: headers}).subscribe({next: (res) => {
        resolve(res as Pratica);
      }, error: (err: HttpErrorResponse) => {
        reject(err.error);
      }})
    });
  }

  inviaFilePratica(praticaId: number, ids: number[], dest: string, message: string) : Promise<boolean> {
    return new Promise((resolve, reject) => {
      var formdata = new FormData();
      var docIds = "";
      ids.forEach((id) => {
        docIds += id + ";";
      });
      docIds = docIds.substring(0, docIds.length - 1);
      formdata.append("praticaId", praticaId.toString());
      formdata.append("message", message);
      formdata.append("dest", dest);
      formdata.append("docIds", docIds);
      var headers = new HttpHeaders();
      headers.append("Content-Type", "multipart/form-data");
      this.http.post(environment.API_URL + "pratiche/sendfiles", formdata, {headers: headers}).subscribe({next: (res) => {
        resolve(true);
      }, error: (err: HttpErrorResponse) => {
        reject(err.error);
      }})
    });
  }

  getPraticaById(id: number) : Promise<GetPraticaResult> {
    return new Promise<GetPraticaResult>((resolve, reject) => {
      this.http.get(environment.API_URL + "pratiche/" + id).subscribe({next: (res: any) => {
        var prat = res as GetPraticaResult;
        resolve(prat);
      }, error: (err: HttpErrorResponse) => {
        reject(err.error);
      }});
    });
  }

  getChat(praticaId: number) : Promise<MessaggioChat[]> {
    return new Promise<MessaggioChat[]>((resolve, reject) => {
      this.http.get(environment.API_URL + "pratiche/chat?praticaId=" + praticaId).subscribe({next: (res: any) => {
        resolve(res as MessaggioChat[]);
      }, error: (err: HttpErrorResponse) => {
        reject(err.error);
      }});
    });
  }

  sendMessaggioChat(messaggio: string, praticaId: number) : Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      var mex = new MessaggioChat();
      mex.praticaId = praticaId;
      mex.messaggio = messaggio;
      this.http.post(environment.API_URL + "pratiche/chat", mex).subscribe({next: (res: any) => {
        resolve(true);
      }, error: (err: HttpErrorResponse) => {
        reject(err.error);
      }});
    });
  }

  annullaPratica(id: number, motivo: MotiviAnnullamentoPratica) : Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.http.delete(environment.API_URL + "pratiche/" + id + "/" + motivo).subscribe({next: (res: any) => {
        resolve(true);
      }, error: (err: HttpErrorResponse) => {
        reject(err.error);
      }});
    });
  }

  eliminaPratica(id: number) : Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.http.delete(environment.API_URL + "pratiche/delete/" + id).subscribe({next: (res: any) => {
        resolve(true);
      }, error: (err: HttpErrorResponse) => {
        reject(err.error);
      }});
    });
  }

  getPraticaDownload(token: string) : Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.get(environment.API_URL + "pratiche/download/" + token).subscribe({next: (res: any) => {
        resolve(res);
      }, error: (err: HttpErrorResponse) => {
        reject(err.error);
      }});
    });
  }

  getStats() {
    return new Promise<any>((resolve, reject) => {
      this.http.get(environment.API_URL + "pratiche/stats").subscribe({next: (stats) => {
        resolve(stats as Stats);
      }, error: (err) => {
        reject();
      }});
    });
  }
}
