<app-lp-template size="lg">
    <div class="p-2">
        <ng-container *ngIf="error">
            <h2 class="text-center">Il link che hai seguito è sbagliato oppure scaduto</h2>
        </ng-container>
        <ng-container *ngIf="!error">
            <h2 class="text-center mt-0">Pratica {{pratica.numerazione}}</h2>
            <h3><strong>Immobile</strong></h3>
            <div class="clr-row">
                <div class="clr-col">
                    <p><strong>Indirizzo: </strong><br/>{{pratica.indirizzo}}</p>
                </div>
                <div class="clr-col">
                    <p><strong>Comune: </strong><br/>{{pratica.citta}}</p>
                </div>
                <div class="clr-col">
                    <p><strong>Provincia: </strong><br/>{{pratica.provincia}}</p>
                </div>
            </div>
            <div class="clr-row">
                <div class="clr-col">
                    <p><strong>Scala: </strong><br/>{{pratica.scala}}</p>
                </div>
                <div class="clr-col">
                    <p><strong>Piano: </strong><br/>{{pratica.piano}}</p>
                </div>
                <div class="clr-col">
                </div>
            </div>
            <h3 class="mt-2"><strong>Contatti amministratore</strong></h3>
            <div class="clr-row">
                <div class="clr-col">
                    <p><strong>Nome e cognome: </strong><br/>{{pratica.amministratore}}</p>
                </div>
                <div class="clr-col">
                    <p><strong>Telefono: </strong><br/>{{pratica.telefonoAmministratore}}</p>
                </div>
                <div class="clr-col">
                    <p><strong>Email: </strong><br/>{{pratica.emailAmministratore}}</p>
                </div>
            </div>
            <h3 class="mt-2"><strong>Proprietari</strong></h3>
            <div class="clr-row" *ngFor="let proprietario of pratica.persone">
                <div class="clr-col">
                    <p><strong>Nome e cognome: </strong><br/>{{proprietario.nome}}</p>
                </div>
                <div class="clr-col">
                    <p><strong>Telefono: </strong><br/>{{proprietario.telefono}}</p>
                </div>
                <div class="clr-col">
                    <p><strong>Email: </strong><br/>{{proprietario.email}}</p>
                </div>
            </div>
            <hr class="mt-2"/>
            <h3 class="text-center mb-2">Scarica i file</h3>
            <ul>
                <li *ngFor="let doc of documentiLabel">
                    {{doc}}
                </li>
            </ul>
            <button class="btn btn-primary btn-block mt-2" (click)="getFile()">Scarica i file della pratica</button>
        </ng-container>
    </div>
</app-lp-template>