<div class="page-container p-2">
    <h2 class="mt-0 text-center">Hai dubbi o devi chiedere informazioni?</h2>
    <p class="text-center">
        <strong>ASSISTENZA PIATTAFORMA</strong><br/>
        Per assistenza sull'utilizzo della piattaforma contatta il numero<br/>
        <a href="tel:+393516027476">+39 351 6027476</a> <a href="https://api.whatsapp.com/send?phone=393516027476" target="_blank" class="wa-icon"><img src="../../assets/img/whatsapp.png"/></a><br/>
        (dal lunedí al venerdí 9.00-12.00 / 14.30-18.00)<br/>
        oppure puoi inviarci una mail a: <br/>
        <a href="mailto:info@praticaok.com">info@praticaok.com</a><br/>
    </p>
    <p class="text-center" *ngIf="interna">
        <strong>ASSISTENZA GESTIONE PRATICHE</strong><br/>
        Per dubbi e domande sulla gestione delle pratiche contatta il tecnico al numero<br/>
        <a href="tel:+390245495197">+39 02 45495197</a><br/>
        (dal lunedí al venerdí dalle 8.30 alle 18.30)

    </p>
</div>