import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AnagraficaUtente } from 'src/app/models/anagrafica-utente';
import { AuthService } from 'src/app/services/auth.service';
import { Albo } from 'src/app/const/user-const';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Router } from '@angular/router';
import { Utils } from 'src/app/utils';

@Component({
  selector: 'app-registrazione-tecnico',
  templateUrl: './registrazione-tecnico.component.html',
  styleUrls: ['./registrazione-tecnico.component.scss']
})
export class RegistrazioneTecnicoComponent {
  public loading: boolean = false;
  public model: AnagraficaUtente = new AnagraficaUtente();
  public confermaPassword: string = "";
  public readonly albo = Albo;

  constructor( private auth: AuthService, private toastr: ToastrService, private spinner: SpinnerService, private router: Router ){}

  register() {
    if(!this.validate()) {
      return;
    }
    this.spinner.show();
    this.auth.registrazioneTecnico(this.model).then(() => {
      this.spinner.dismiss();
      this.router.navigate(["/thankyou-registrazione"]);
    }).catch((err: string) => {
      var errore = "Errore sconosciuto";
      switch(err) {
        case "error.user.already_exists":
          errore = "Esiste già un utente con questa mail";
          break;
        case "error.user.password_invalid":
          errore = "La password non è valida";
          break;
        case "error.user.email_invalid":
          errore = "Inserisci un indirizzo email valido";
          break;
        case "error.user.internal":
          errore = "Errore interno, contattaci a info@praticaok.com";
          break;
      }
      this.toastr.error(errore);
      this.spinner.dismiss();
    });
  }

  validate() : boolean {
    if(Utils.parseDataString(this.model.tecnico_dataNascita) == null) {
      this.toastr.error("Inserisci una data di nascita valida");
      return false
    }
    if(!this.auth.validatePassword(this.model.password)) {
      this.toastr.error("La password deve essere lunga almeno 8 caratteri e contenere almeno una lettera minuscola, una minuscola e un numero");
      return false;
    }
    if(this.model.password != this.confermaPassword) {
      this.toastr.error("Le due password devono coincidere");
      return false;
    }
    return true;
  }
}
