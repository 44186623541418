<div class="page-container p-2">
    <h2 class="text-center">Utenti</h2>
    <div class="text-center"><button class="btn btn-primary" (click)="crea()">+ Nuovo utente</button></div>
    <div class="table-container">
        <table class="table">
            <thead>
                <th>Nome e cognome</th>
                <th>Email</th>
                <th>Ruolo</th>
                <th></th>
            </thead>
            <tbody>
                <tr *ngFor="let user of utenti; index as i">
                    <td>{{user.nome}}</td>
                    <td>{{user.email}}</td>
                    <td>{{user.ruolo}}</td>
                    <td>
                        <div class="btn-group btn-primary" *ngIf="user.ruolo != 'Team Leader'">
                            <button class="btn btn-primary btn-icon" (click)="edit(user)"><fa-icon [icon]="faPenToSquare"></fa-icon></button>
                            <button class="btn btn-danger btn-icon"><fa-icon [icon]="faTrashCan"></fa-icon></button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <ng-container *ngIf="disabilitati.length > 0">
        <h2 class="text-center">Disabilitati</h2>
        <div class="table-container">
            <table class="table">
                <thead>
                    <th>Nome e cognome</th>
                    <th>Email</th>
                    <th>Ruolo</th>
                    <th></th>
                </thead>
                <tbody>
                    <tr *ngFor="let user of disabilitati; index as i">
                        <td>{{user.nome}}</td>
                        <td>{{user.email}}</td>
                        <td>{{user.ruolo}}</td>
                        <td>
                            <div class="btn-group btn-primary" *ngIf="user.ruolo != 'Team Leader'">
                                <button class="btn btn-primary btn-icon" (click)="edit(user)"><fa-icon [icon]="faPenToSquare"></fa-icon></button>
                                <button class="btn btn-danger btn-icon"><fa-icon [icon]="faTrashCan"></fa-icon></button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>
</div>