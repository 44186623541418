export class AnagraficaSocieta {
    id: number = 0;
    ragioneSociale: string = "";
    piva: string = "";
    indirizzo: string = "";
    cap: string = "";
    citta: string = "";
    provincia: string = "";
    email: string = "";
    emailCC: string = "";
    telefono: string = "";
    attivo: boolean = false;
    cf: string = "";
    accettatoDaStudio: boolean = false;
    regione: string = "";
    referente: string = "";
    pec: string = "";
    sdi: string = "";
    logo: string = "";
    importoQuota: number = 0;
    dataScadenzaQuota: string = "";
    notaAmministratore: string = "";
    interna: boolean = false;
    ibrida: boolean = false;

    errorLoadingLogo: boolean = false;
}