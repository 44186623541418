import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { faPenToSquare, faTrashCan, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { AnagraficaUtente } from 'src/app/models/anagrafica-utente';
import { CercaTecniciModel } from 'src/app/models/cerca-tecnici-model';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UtentiService } from 'src/app/services/utenti.service';

@Component({
  selector: 'app-tecnici',
  templateUrl: './tecnici.component.html',
  styleUrls: ['./tecnici.component.scss']
})
export class TecniciComponent {
  showInterni = true;
  showEsterni = false;
  tecnici: AnagraficaUtente[] = [];
  cercaModel: CercaTecniciModel = new CercaTecniciModel();
  faTriangleExclamation = faTriangleExclamation;
  faPenToSquare = faPenToSquare;
  faTrashCan = faTrashCan;

  constructor(private toastr: ToastrService, private utentiSrv: UtentiService, private spinner: SpinnerService, private router: Router) {}

  ngOnInit() {
    this.cerca();
  }

  cerca() {
    this.spinner.show();
    this.utentiSrv.getTecniciAdmin(this.cercaModel).then((tec) => {
      this.tecnici = tec;
      this.spinner.dismiss();
    }).catch((err) => {
      this.toastr.error("Errore nel recuperare la lista dei tecnici");
      this.spinner.dismiss();
    });
  }

  switchInterni() {
    this.showInterni = true;
    this.showEsterni = false;
  }

  switchEsterni() {
    this.showInterni = false;
    this.showEsterni = true;
  }

  edit(tec: AnagraficaUtente) {
    this.router.navigate(["anagrafica-tecnico/" + tec.id]);
  }

  crea() {
    this.router.navigate(["anagrafica-tecnico", { new: "true" }])
  }
}
