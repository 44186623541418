import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { AnagraficaSocieta } from 'src/app/models/anagrafica-societa';
import { AnagraficaUtente } from 'src/app/models/anagrafica-utente';
import { CercaSocietaModel } from 'src/app/models/cerca-societa-model';
import { SocietaService } from 'src/app/services/societa.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UtentiService } from 'src/app/services/utenti.service';

@Component({
  selector: 'app-teamleaders',
  templateUrl: './teamleaders.component.html',
  styleUrls: ['./teamleaders.component.scss']
})
export class TeamleadersComponent {

  leaders: AnagraficaUtente[] = [];
  agenzie: AnagraficaSocieta[] = [];
  agenzieFiltered: AnagraficaSocieta[] = [];
  showModalSocieta = false;
  selectedLeader: AnagraficaUtente = new AnagraficaUtente();
  indexAgenzia: number = 0;
  faPenToSquare = faPenToSquare;
  faTrashCan = faTrashCan;

  constructor(private societaSrv: SocietaService, private utentiSrv: UtentiService, private toastr: ToastrService, private spinner: SpinnerService, private router: Router) {}

  ngOnInit() {
    this.loadLeaders();
    this.societaSrv.getAllSocieta(new CercaSocietaModel()).then(res => {
      this.agenzie = [];
      this.agenzie = this.agenzie.concat(res.interne).concat(res.esterne).sort((a, b) => {
        let x = a.ragioneSociale.toLowerCase();
        let y = b.ragioneSociale.toLowerCase();
        if (x < y) {return -1;}
        if (x > y) {return 1;}
        return 0;}
      );
      this.agenzieFiltered = this.agenzie;
    });
  }

  reloadAgenzieFiltered() {
    this.agenzieFiltered = this.agenzie.filter((ag) => {
      return this.selectedLeader.societa.filter((ag2) => ag2.id == ag.id).length == 0;
    });
  }

  loadLeaders() {
    this.spinner.show();
    this.utentiSrv.getTeamLeaders().then((res: AnagraficaUtente[]) => {
      this.leaders = res;
    }).catch((err: HttpErrorResponse) => {
      this.toastr.error("Errore nel recuperare l'elenco dei team leader");
    }).finally(() => {
      this.spinner.dismiss();
    });
  }

  crea() {
    this.router.navigate(["utente", {leader: true, new: "true"}]);
  }

  edit(user: AnagraficaUtente) {
    this.router.navigate(["utente/" + user.id, {leader: true}]);
  }

  openModalSocieta(user: AnagraficaUtente) {
    this.selectedLeader = user;
    this.reloadAgenzieFiltered();
    this.showModalSocieta = true;
  }

  rimuoviAgenzia(soc: AnagraficaSocieta) {
    if(confirm("Confermi di voler rimuovere l'associazione tra questo team leader e l'agenzia " + soc.ragioneSociale + "?")) {
      this.spinner.show();
      this.societaSrv.rimuoviUtenteDaAgenzia(soc.id, this.selectedLeader.id).then(() => {
        this.loadLeaders();
        this.selectedLeader.societa.splice(this.selectedLeader.societa.indexOf(soc), 1);
        this.toastr.success("Agenzia rimossa dalla gestione del team leader");
      }).catch(() => {
        this.toastr.success("Errore con la rimozione dell'agenzia");
      }).finally(() => {
        this.spinner.dismiss();
        this.reloadAgenzieFiltered();
      });
    }
  }

  aggiungiAgenzia() {
    if(confirm("Confermi di voler associare questo team leader all'agenzia " + this.agenzieFiltered[this.indexAgenzia].ragioneSociale + "?")) {
      this.spinner.show();
      this.societaSrv.aggiungiUtenteAdAgenzia(this.agenzieFiltered[this.indexAgenzia].id, this.selectedLeader.id).then(() => {
        this.loadLeaders();
        this.selectedLeader.societa.push(this.agenzieFiltered[this.indexAgenzia]);
        this.toastr.success("Agenzia associata correttamente al team leader");
      }).catch(() => {
        this.toastr.success("Errore con l'assegnazione dell'agenzia");
      }).finally(() => {
        this.spinner.dismiss();
        this.reloadAgenzieFiltered();
      });
    }
  }


}
