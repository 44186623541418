import { Component } from '@angular/core';

@Component({
  selector: 'app-thankyou-registrazione',
  templateUrl: './thankyou-registrazione.component.html',
  styleUrls: ['./thankyou-registrazione.component.scss']
})
export class ThankyouRegistrazioneComponent {

}
