import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-agenzie-select',
  templateUrl: './agenzie-select.component.html',
  styleUrls: ['./agenzie-select.component.scss']
})
export class AgenzieSelectComponent {

  constructor(public auth: AuthService) {}
}
