export class CercaPraticheModel {
    cercaAgenzia: string = "";
    cercaTecnico: string = "";
    cercaConsulente: string = "";
    cercaProprietario: string = "";
    cercaComune: string = "";
    cercaIndirizzo: string = "";
    cercaStatoInLavorazione: boolean = false;
    cercaStatoConclusa: boolean = false;
    cercaStatoRogitata: boolean = false;
    cercaStatoFerma: boolean = false;
    cercaStatoNuova: boolean = false;
    agenziaId: number | undefined;
    page: number = 1;
    pageSize: number = 20;
}