export enum StatiPratica {
    IN_LAVORAZIONE,
    CONCLUSA,
    ROGITATA,
    FERMA,
    NUOVA,
    ANNULLATA
}

export enum UbicazioniChiavi {
    PROPRIETARIO,
    AGENZIA
}

export enum TipiPersone {
    PROPRIETARIO,
    REFERENTE_PROPRIETA,
    ACQUIRENTE,
    REFERENTE_ACQUIRENTE,
    NOTAIO,
    REFERENTE_NOTAIO
}

export enum MotiviFermoPratica {
    NON_SPECIFICATO,
    RIFIUTO_DEL_CLIENTE,
    AGENZIA_NO_CONTATTO_CLIENTE,
    PRATICA_INSANABILE,
    PROPRIETARIO_IRRAGGIUNGIBILE,
    INTERVENTO_ALTRO_TECNICO,
    MANCANZA_DOCUMENTI_AGENZIA,
    MANCANZA_NUMERO_PROPRIETARIO,
    ATTESA_RISPOSTA_CLIENTE,
    ATTESA_RISPOSTA_AGENTE,
    PRATICA_DOPPIA
}


export enum MotiviAnnullamentoPratica {
    SCADUTO_INCARICO,
    IMMOBILE_ROGITATO,
    CLIENTE_NON_VUOLE_VENDERE,
    AGENZIA_RESCISSO_MANDATO
}

export enum StatiPresaInCarico {
    ATTESA_SCELTA_AGENZIA,
    AGENZIA_HA_TECNICO_MA_NON_INVITATO,
    AGENZIA_NO_TECNICO,
    AGENZIA_HA_TECNICO_MA_MANCA_QUALCOSA,
    AGENZIA_ASSOCIATA_TECNICO
}

export enum DestinazioneImmobile {
    ABITAZIONE,
    UFFICIO,
    NEGOZIO,
    LABORATORIO
}

export const MotiviFermoLabel: Record<MotiviFermoPratica, string> = {
    [MotiviFermoPratica.NON_SPECIFICATO]: "Non specificato",
    [MotiviFermoPratica.RIFIUTO_DEL_CLIENTE]: "Rifiuto del cliente",
    [MotiviFermoPratica.AGENZIA_NO_CONTATTO_CLIENTE]: "L'agenzia non vuole che il cliente venga contattato",
    [MotiviFermoPratica.PRATICA_INSANABILE]: "Pratica insanabile",
    [MotiviFermoPratica.PROPRIETARIO_IRRAGGIUNGIBILE]: "Proprietario irraggiungibile",
    [MotiviFermoPratica.INTERVENTO_ALTRO_TECNICO]: "Intervento di un altro tecnico",
    [MotiviFermoPratica.MANCANZA_DOCUMENTI_AGENZIA]: "Mancanza documenti caricati da agenzia",
    [MotiviFermoPratica.MANCANZA_NUMERO_PROPRIETARIO]: "Mancanza del numero di telefono del proprietario",
    [MotiviFermoPratica.ATTESA_RISPOSTA_CLIENTE]: "In attesa di risposta da parte del cliente",
    [MotiviFermoPratica.ATTESA_RISPOSTA_AGENTE]: "In attesa di risposta da parte dell'agente",
    [MotiviFermoPratica.PRATICA_DOPPIA]: "Pratica caricata due volte",
}

export const MotiviAnnullamentoLabel: Record<MotiviAnnullamentoPratica, string> = {
    [MotiviAnnullamentoPratica.SCADUTO_INCARICO]: "Scaduto incarico",
    [MotiviAnnullamentoPratica.IMMOBILE_ROGITATO]: "Immobile rogitato",
    [MotiviAnnullamentoPratica.CLIENTE_NON_VUOLE_VENDERE]: "Il cliente non vuole più vendere",
    [MotiviAnnullamentoPratica.AGENZIA_RESCISSO_MANDATO]: "L'agenzia ha rescisso il mandato"
}

export enum CampiForm {
    INDIRIZZO = 1,
    CITTA = 2,
    PROVINCIA = 3,
    CAP = 4,
    SCALA = 5,
    PIANO = 6,
    UBICAZIONE_CHIAVI = 7,
    AMMINISTRATORE = 8,
    TELEFONO_AMMINISTRATORE = 9,
    EMAIL_AMMINISTRATORE = 10,
    N_VANI = 11,
    DATA_SCADENZA_MANDATO = 12,
    IMPORTO = 13,
    STATO = 14,
    DATA_ANNUNCIO = 15,
    PREZZO = 16,
    BROCHURE_TITOLO = 17,
    SPESE_CONDOMINIALI = 18,
    SUPERFICIE_NETTA = 19,
    SUPERFICIE_COMMERCIALE = 20,
    DATA_SCADENZA_APE = 21,
    SOPRALUOGO = 22,
    DATA_SOPRALUOGO = 23,
    DATA_FERMO_PRATICA = 24,
    MOTIVO_FERMO_PRATICA = 25,
    BROCHURE_DESCRIZIONE = 26,
    BROCHURE_INDIRIZZO_MAPS = 27,
    RENDITA_CATASTALE = 28,
    CATEGORIA_CATASTALE = 29,
    PERSONA_NOME = 30,
    PERSONA_TELEFONO = 31,
    PERSONA_EMAIL = 32,
    DOCUMENTO = 33,
    VOLTURALUCE_NUOVAINSTALLAZIONE = 34,
    VOLTURALUCE_RESIDENTE = 35,
    VOLTURALUCE_CONTATOREPOD = 36,
    VOLTURALUCE_POTENZARICHIESTA = 36,
    VOLTURALUCE_DENOMINAZIONESOCIETA = 37,
    VOLTURALUCE_NOME = 38,
    VOLTURALUCE_COGNOME = 39,
    VOLTURALUCE_TELEFONO = 40,
    VOLTURALUCE_CF = 41,
    VOLTURALUCE_PIVA = 42,
    VOLTURALUCE_DESTINAZIONEIMMOBILE = 43,
    VOLTURALUCE_INDIRIZZO = 44,
    VOLTURALUCE_CAP = 45,
    VOLTURALUCE_CITTA = 46,
    VOLTURALUCE_PROVINCIA = 47,
    VOLTURALUCE_REGIONE = 48,
    VOLTURALUCE_MODALITARECAPITOFATTURA = 49,
    VOLTURALUCE_EMAILRECAPITOBOLLETTAFATTURA = 50,
    VOLTURALUCE_MODALITAPAGAMENTOFATTURA = 51,
    VOLTURALUCE_INTESTATARIOCONTO = 52,
    VOLTURALUCE_BANCA = 53,
    VOLTURALUCE_IBAN = 54,
    VOLTURALUCE_SWIFT = 55,
    VOLTURALUCE_RESIDENZA_INDIRIZZO = 56,VOLTURALUCE_RESIDENZA_CAP = 57,
    VOLTURALUCE_RESIDENZA_CITTA = 58,
    VOLTURALUCE_RESIDENZA_PROVINCIA = 59,
    VOLTURALUCE_RESIDENZA_REGIONE = 60,
    VOLTURALUCE_RESIDENZA_STATO = 61,
    VOLTURAGAS_NUOVAINSTALLAZIONE = 62,
    VOLTURAGAS_RESIDENTE = 63,
    VOLTURAGAS_CONTATOREPDR = 64,
    VOLTURAGAS_DESTINAZIONEGAS = 65,
    VOLTURAGAS_DENOMINAZIONESOCIETA = 66,
    VOLTURAGAS_NOME = 67,
    VOLTURAGAS_COGNOME = 68,
    VOLTURAGAS_TELEFONO = 69,
    VOLTURAGAS_CF = 70,
    VOLTURAGAS_PIVA = 71,
    VOLTURAGAS_DESTINAZIONEIMMOBILE = 72,
    VOLTURAGAS_INDIRIZZO = 73,
    VOLTURAGAS_CAP = 74,
    VOLTURAGAS_CITTA = 75,
    VOLTURAGAS_PROVINCIA = 76,
    VOLTURAGAS_REGIONE = 77,
    VOLTURAGAS_MODALITARECAPITOFATTURA = 78,
    VOLTURAGAS_EMAILRECAPITOBOLLETTAFATTURA = 79,
    VOLTURAGAS_MODALITAPAGAMENTOFATTURA = 80,
    VOLTURAGAS_INTESTATARIOCONTO = 81,
    VOLTURAGAS_BANCA = 82,
    VOLTURAGAS_IBAN = 83,
    VOLTURAGAS_SWIFT = 84,
    VOLTURAGAS_RESIDENZA_INDIRIZZO = 85,
    VOLTURAGAS_RESIDENZA_CAP = 86,
    VOLTURAGAS_RESIDENZA_CITTA = 87,
    VOLTURAGAS_RESIDENZA_PROVINCIA = 88,
    VOLTURAGAS_RESIDENZA_REGIONE = 89,
    VOLTURAGAS_RESIDENZA_STATO = 90,
    CONSULENTE = 91
}