<div class="p-1">
    <div class="text-right">
        <button type="button"class="btn btn-primary" (click)="submit()">Salva</button>
        <button type="button"class="btn" (click)="annulla()">Annulla</button>
    </div>
    <div>
        <h2 *ngIf="edit" class="mt-0 text-center">Pratica {{prat.numerazione}} <span *ngIf="prat.indirizzo.length > 0">- {{prat.indirizzo}}</span> <span *ngIf="prat.citta.length > 0"> - {{prat.citta}}</span> <span *ngIf="prat.provincia.length > 0"> ({{prat.provincia}})</span></h2>
        <h2 *ngIf="!edit" class="mt-0 text-center">Nuova Pratica</h2>
    </div>
</div>
<div class="custom-tabs-switch pratica-tabs">
    <div class="tab" [ngClass]="{'active': activeTab == 0}" (click)="setActiveTab(0)"><fa-icon [icon]="faHouse"></fa-icon>Anagrafica immobile</div>
    <div class="tab" [ngClass]="{'active': activeTab == 1}" (click)="setActiveTab(1)"><fa-icon [icon]="faSuitcase"></fa-icon>Anagrafica vendita</div>
    <div class="tab" [ngClass]="{'active': activeTab == 2}" (click)="setActiveTab(2)"><fa-icon [icon]="faFire"></fa-icon>Volture</div>
    <div class="tab" [ngClass]="{'active': activeTab == 3}" (click)="setActiveTab(3)"><fa-icon [icon]="faEnvelope"></fa-icon>Invia files pratica</div>
    <div class="tab" [ngClass]="{'active': activeTab == 4}" (click)="setActiveTab(4)"><fa-icon [icon]="faMap"></fa-icon>Brochure</div>
    <div class="tab" [ngClass]="{'active': activeTab == 5}" (click)="setActiveTab(5)"><fa-icon [icon]="faComments"></fa-icon>Chat</div>
</div>
<div class="logs container-large" *ngIf="logs.length > 0">
    <h2>Ultime modifiche eseguite {{auth.isTecnico || auth.isAdmin ? "dall'agenzia" : "dal tecnico"}}</h2>
    
    <ng-container *ngFor="let log of logs" class="log">
        <p class="dataLog">{{log.data}}</p>
        <ul>
            <li *ngFor="let att of log.attivita">{{att}}</li>
            <li *ngIf="log.nuovoMessaggioChat">Nuovo messaggio in chat</li>
        </ul>
    </ng-container>
</div>
<form clrForm clrLayout="vertical" id="praticaForm">
    <div class="content content-anagrafica-immobile input-block" [ngClass]="{'active': activeTab == 0}">
        <div class="page-container container-large mt-0">
            <h3 class="section-title"><fa-icon [icon]="faHouse"></fa-icon> Immobile</h3>
            <div class="content-padding">
                <div class="clr-row">
                    <div class="clr-col-md-2">
                        <p><strong>Agenzia</strong><br/>{{prep.societa.ragioneSociale}}</p>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-select-container>
                            <label>Consulente <a *ngIf="auth.isTecnicoInterno || auth.isAdmin" href="javascript:void(0);" (click)="openModalConsulente()">(dati consulente)</a></label>
                            <select clrSelect [(ngModel)]="prat.consulenteId" name="consulenteId" [disabled]="auth.isTecnico && !auth.isTecnicoInterno" [attr.data-campo]="CampiForm.CONSULENTE">
                                <option *ngFor="let cons of prep.listaConsulenti" value="{{cons.id}}">{{cons.nome}}</option>
                            </select>
                        </clr-select-container>
                    </div>
                    <div class="clr-col-md-3" *ngIf="!auth.isTecnico || (auth.isTecnico && auth.isTecnicoInterno)">
                        <clr-select-container>
                            <label>Tecnico</label>
                            <select clrSelect [(ngModel)]="prat.tecnicoId" name="tecnicoId" [disabled]="tecnicoDisabled" (change)="changeTecnico()">
                                <option value="" *ngIf="!prep.isSocietaInterna">-- Nessun tecnico</option>
                                <option *ngFor="let tec of prep.listaTecnici" value="{{tec.id}}">{{tec.nome}}</option>
                            </select>
                        </clr-select-container>
                    </div>
                    <div class="clr-col-md-2" *ngIf="!auth.isTecnico">
                        <clr-radio-container clrInline>
                            <label>Rogito eseguito</label>
                            <clr-radio-wrapper>
                                <input type="radio" clrRadio name="rogito" [value]="true" [(ngModel)]="rogito" (ngModelChange)="rogitoChanged($event)" />
                                <label>Si</label>
                            </clr-radio-wrapper>
                            <clr-radio-wrapper>
                                <input type="radio" clrRadio name="rogito" [value]="false" [(ngModel)]="rogito" (ngModelChange)="rogitoChanged($event)" />
                                <label>No</label>
                            </clr-radio-wrapper>
                        </clr-radio-container>
                    </div>
                    <div class="clr-col-md-2" *ngIf="!auth.isTecnico && !auth.isAdmin">
                        <p><strong>Stato pratica</strong><br/>{{statoString}}</p>
                    </div>
                    <div class="clr-col-md-2" *ngIf="auth.isTecnico || auth.isAdmin">
                        <clr-select-container>
                            <label>Stato pratica</label>
                            <select clrSelect [(ngModel)]="prat.stato" name="stato" (ngModelChange)="statoChanged($event)" [attr.data-campo]="CampiForm.STATO">
                                <option [value]="statiPratica.IN_LAVORAZIONE">In lavorazione</option>
                                <option [value]="statiPratica.CONCLUSA">Conclusa</option>
                                <option [value]="statiPratica.ROGITATA">Rogitata</option>
                                <option [value]="statiPratica.FERMA">Ferma</option>
                                <option [value]="statiPratica.NUOVA">Nuova</option>
                                <option [value]="statiPratica.ANNULLATA">Annullata</option>
                            </select>
                        </clr-select-container>
                    </div>
                    <div class="clr-col-md-12" *ngIf="prat.stato == statiPratica.ANNULLATA">
                        <p><strong>Motivo annullamento pratica</strong><br/>{{motiviAnnullamentoLabel[prat.motivoAnnullamentoPratica]}}</p>
                    </div>
                    <div class="clr-col-md-5" *ngIf="prat.stato == statiPratica.FERMA">
                        <clr-select-container>
                            <label>Motivo fermo pratica</label>
                            <select clrSelect [(ngModel)]="prat.motivoFermoPratica" name="motivoFermoPratica" [attr.data-campo]="CampiForm.MOTIVO_FERMO_PRATICA" [disabled]="!auth.isTecnico && !auth.isAdmin">
                                <option [value]="motiviFermoPratica.NON_SPECIFICATO">{{MotiviFermoLabel[motiviFermoPratica.NON_SPECIFICATO]}}</option>
                                <option [value]="motiviFermoPratica.RIFIUTO_DEL_CLIENTE">{{MotiviFermoLabel[motiviFermoPratica.RIFIUTO_DEL_CLIENTE]}}</option>
                                <option [value]="motiviFermoPratica.AGENZIA_NO_CONTATTO_CLIENTE">{{MotiviFermoLabel[motiviFermoPratica.AGENZIA_NO_CONTATTO_CLIENTE]}}</option>
                                <option [value]="motiviFermoPratica.PRATICA_INSANABILE">{{MotiviFermoLabel[motiviFermoPratica.PRATICA_INSANABILE]}}</option>
                                <option [value]="motiviFermoPratica.PROPRIETARIO_IRRAGGIUNGIBILE">{{MotiviFermoLabel[motiviFermoPratica.PROPRIETARIO_IRRAGGIUNGIBILE]}}</option>
                                <option [value]="motiviFermoPratica.INTERVENTO_ALTRO_TECNICO">{{MotiviFermoLabel[motiviFermoPratica.INTERVENTO_ALTRO_TECNICO]}}</option>
                                <option [value]="motiviFermoPratica.MANCANZA_DOCUMENTI_AGENZIA">{{MotiviFermoLabel[motiviFermoPratica.MANCANZA_DOCUMENTI_AGENZIA]}}</option>
                                <option [value]="motiviFermoPratica.MANCANZA_NUMERO_PROPRIETARIO">{{MotiviFermoLabel[motiviFermoPratica.MANCANZA_NUMERO_PROPRIETARIO]}}</option>
                                <option [value]="motiviFermoPratica.ATTESA_RISPOSTA_CLIENTE">{{MotiviFermoLabel[motiviFermoPratica.ATTESA_RISPOSTA_CLIENTE]}}</option>
                                <option [value]="motiviFermoPratica.ATTESA_RISPOSTA_AGENTE">{{MotiviFermoLabel[motiviFermoPratica.ATTESA_RISPOSTA_AGENTE]}}</option>
                                <option [value]="motiviFermoPratica.PRATICA_DOPPIA">{{MotiviFermoLabel[motiviFermoPratica.PRATICA_DOPPIA]}}</option>
                            </select>
                        </clr-select-container>
                    </div>
                </div>
                <h4>Dove si trova l'immobile?</h4>
                <div class="clr-row">
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Indirizzo</label>
                            <input clrInput type="text" [(ngModel)]="prat.indirizzo" name="indirizzo" [attr.data-campo]="CampiForm.INDIRIZZO"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Comune</label>
                            <input clrInput type="text" [(ngModel)]="prat.citta" name="citta" [attr.data-campo]="CampiForm.CITTA"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-2">
                        <clr-input-container>
                            <label>Provincia</label>
                            <input clrInput type="text" [(ngModel)]="prat.provincia" name="provincia" [attr.data-campo]="CampiForm.PROVINCIA"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-2">
                        <clr-input-container>
                            <label>Scala</label>
                            <input clrInput type="text" [(ngModel)]="prat.scala" name="scala" [attr.data-campo]="CampiForm.SCALA"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-2">
                        <clr-input-container>
                            <label>Piano</label>
                            <input clrInput type="text" [(ngModel)]="prat.piano" name="piano" [attr.data-campo]="CampiForm.PIANO"/>
                        </clr-input-container>
                    </div>
                </div>
                <h4>Contatti amministratore</h4>
                <div class="clr-row">
                    <div class="clr-col-md-4">
                        <clr-input-container>
                            <label>Nome e cognome</label>
                            <input clrInput type="text" [(ngModel)]="prat.amministratore" name="amministratore" [attr.data-campo]="CampiForm.AMMINISTRATORE"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-4">
                        <clr-input-container>
                            <label>Telefono</label>
                            <input clrInput type="text" [(ngModel)]="prat.telefonoAmministratore" name="telefonoAmministratore" [attr.data-campo]="CampiForm.TELEFONO_AMMINISTRATORE"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-4">
                        <clr-input-container>
                            <label>Email</label>
                            <input clrInput type="text" [(ngModel)]="prat.emailAmministratore" name="emailAmministratore" [attr.data-campo]="CampiForm.EMAIL_AMMINISTRATORE"/>
                        </clr-input-container>
                    </div>
                </div>
                <h4>Chi ha le chiavi dell'immobile?
                    <a href="javascript://" role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg tooltip-top-right">
                        <fa-icon [icon]="faCircleInfo"></fa-icon>
                        <span class="tooltip-content">Le chiavi serviranno al tecnico per organizzare il sopraluogo.<br/>Ricordati di avvisare il proprietario che sarà contattato dal tecnico</span>
                    </a>
                </h4>
                <div class="clr-row">
                    <div class="clr-col-md-3">
                        <clr-select-container>
                            <label>Sede</label>
                            <select clrSelect [(ngModel)]="prat.ubicazioneChiavi" name="ubicazioneChiavi" [attr.data-campo]="CampiForm.UBICAZIONE_CHIAVI">
                                <option [value]="0">Proprietario</option>
                                <option [value]="1">Agenzia</option>
                            </select>
                        </clr-select-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-radio-container clrInline>
                            <label>Sopraluogo eseguito</label>
                            <clr-radio-wrapper>
                                <input type="radio" clrRadio name="sopraluogo" [value]="true" [(ngModel)]="prat.sopraluogo" [attr.data-campo]="CampiForm.SOPRALUOGO"/>
                                <label>Si</label>
                            </clr-radio-wrapper>
                            <clr-radio-wrapper>
                                <input type="radio" clrRadio name="sopraluogo" [value]="false" [(ngModel)]="prat.sopraluogo" [attr.data-campo]="CampiForm.SOPRALUOGO"/>
                                <label>No</label>
                            </clr-radio-wrapper>
                        </clr-radio-container>
                    </div>
                    <div class="clr-col-md-3" [ngClass]="{'d-none': !prat.sopraluogo}">
                        <clr-date-container>
                            <label>Data sopraluogo</label>
                            <input type="date" autocomplete="off" clrDate name="dataSopraluogo" [(ngModel)]="prat.dataSopraluogo" placeholder="GG/MM/AAAA" maxlength="10" [attr.data-campo]="CampiForm.DATA_SOPRALUOGO" />
                        </clr-date-container>
                    </div>
                </div>
            </div>
        </div>

        
        <div class="page-container container-large mt-0">
            <h3 class="section-title"><fa-icon [icon]="faUser"></fa-icon> Proprietari ({{proprietari.length}})
                <a href="javascript://" role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg tooltip-top-left">
                    <fa-icon [icon]="faCircleInfo"></fa-icon>
                    <span class="tooltip-content">Ricordati di inserire sempre carta d'identità o passaporto e codice fiscale.</span>
                </a>
            </h3>
            <div class="content-padding">
                <div class="text-center"><button type="button"class="btn btn-link" (click)="addProprietario()">Aggiungi proprietario</button></div>
                <div *ngFor="let prop of proprietari; let i = index" [attr.data-index]="i">
                    <div class="clr-row persona-row">
                        <div class="clr-col-md">
                            <clr-input-container>
                                <label>Nome e cognome</label>
                                <input clrInput type="text" [(ngModel)]="proprietari[i].nome" name="proprietari[{{i}}].nome" [attr.data-campo]="CampiForm.PERSONA_NOME + '-' + prop.id"/>
                            </clr-input-container>
                        </div>
                        <div class="clr-col-md">
                            <clr-input-container>
                                <label>Telefono</label>
                                <input clrInput type="text" [(ngModel)]="proprietari[i].telefono" name="proprietari[{{i}}].telefono" [attr.data-campo]="CampiForm.PERSONA_TELEFONO + '-' + prop.id"/>
                            </clr-input-container>
                        </div>
                        <div class="clr-col-md">
                            <clr-input-container>
                                <label>Email</label>
                                <input clrInput type="text" [(ngModel)]="proprietari[i].email" name="proprietari[{{i}}].email" [attr.data-campo]="CampiForm.PERSONA_EMAIL + '-' + prop.id"/>
                            </clr-input-container>
                        </div>
                        <div class="clr-col-md-1 col-delete"><button type="button"class="btn btn-danger-outline btn-icon" (click)="deleteProprietario(i)"><fa-icon [icon]="faTrashCan"></fa-icon></button></div>
                    </div>
                    <div class="clr-row">
                        <div class="clr-col-12 clr-col-md col-documenti">
                            <div class="col-documenti-inner">
                                <p class="documento-label">{{TipiLabel[TipiDocumento.PR]}}</p>
                                <div *ngFor="let doc of prop.documentiIdentita; let i2 = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                    <button type="button" (click)="deleteDocumentoPersona('proprietario', TipiDocumento.PR, i, i2)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                    <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                                </div>
                                <div><button class="btn btn-link" (click)="aggiungiDocumentoPersona('proprietario', TipiDocumento.PR, i)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                            </div>
                        </div>
                        <div class="clr-col-12 clr-col-md col-documenti">
                            <div class="col-documenti-inner">
                                <p class="documento-label">{{TipiLabel[TipiDocumento.CF]}}</p>
                                <div *ngFor="let doc of prop.documentiCF; let i2 = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                    <button type="button"(click)="deleteDocumentoPersona('proprietario', TipiDocumento.CF, i, i2)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                    <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                                </div>
                                <div><button class="btn btn-link" (click)="aggiungiDocumentoPersona('proprietario', TipiDocumento.CF, i)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                            </div>
                        </div>
                        <div class="clr-col-12 clr-col-md col-documenti">
                            <div class="col-documenti-inner">
                                <p class="documento-label">{{TipiLabel[TipiDocumento.PRIV]}}</p>
                                <div *ngFor="let doc of prop.documentiPrivacy; let i2 = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                    <button type="button"(click)="deleteDocumentoPersona('proprietario', TipiDocumento.PRIV, i, i2)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                    <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                                </div>
                                <div><button class="btn btn-link" (click)="aggiungiDocumentoPersona('proprietario', TipiDocumento.PRIV, i)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                            </div>
                        </div>
                        <div class="clr-col-md-1"></div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="page-container container-large mt-0">
            <h3 class="section-title"><fa-icon [icon]="faUsers"></fa-icon> Referenti ({{referenti.length}})
                <a href="javascript://" role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg tooltip-top-left">
                    <fa-icon [icon]="faCircleInfo"></fa-icon>
                    <span class="tooltip-content">Chi può contattare il tecnico per aggiornare il proprietario in caso di necessità.</span>
                </a>
            </h3>
            <div class="content-padding">
                <div class="text-center"><button type="button"class="btn btn-link" (click)="addReferenteProprieta()">Aggiungi referente</button></div>
                <div class="clr-row persona-row" *ngFor="let ref of referenti; let i = index" [attr.data-index]="i">
                    <div class="clr-col-md">
                        <clr-input-container>
                            <label>Nome e cognome</label>
                            <input clrInput type="text" [(ngModel)]="referenti[i].nome" name="referenti[{{i}}].nome" [attr.data-campo]="CampiForm.PERSONA_NOME + '-' + ref.id"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md">
                        <clr-input-container>
                            <label>Telefono</label>
                            <input clrInput type="text" [(ngModel)]="referenti[i].telefono" name="referenti[{{i}}].telefono" [attr.data-campo]="CampiForm.PERSONA_TELEFONO + '-' + ref.id"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md">
                        <clr-input-container>
                            <label>Email</label>
                            <input clrInput type="text" [(ngModel)]="referenti[i].email" name="referenti[{{i}}].email" [attr.data-campo]="CampiForm.PERSONA_TELEFONO + '-' + ref.id"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-1 col-delete"><button type="button"class="btn btn-danger-outline btn-icon" (click)="deleteReferenteProprieta(i)"><fa-icon [icon]="faTrashCan"></fa-icon></button></div>
                </div>
            </div>
        </div>
        <div class="page-container container-large mt-0">
            <h3 class="section-title"><fa-icon [icon]="faFolder"></fa-icon> Documenti forniti da agenzia
                <a href="javascript://" role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg tooltip-top-left">
                    <fa-icon [icon]="faCircleInfo"></fa-icon>
                    <span class="tooltip-content">Ricorda che puoi caricare solo documenti in formato PDF</span>
                </a>
            </h3>
            <div class="content-padding">
                <div class="clr-row">
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.ATNOT_AGENZIA]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.ATNOT_AGENZIA]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.ATNOT_AGENZIA, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.ATNOT_AGENZIA)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.APE_AGENZIA]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.APE_AGENZIA]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.APE_AGENZIA, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.APE_AGENZIA)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.SCCAT_AGENZIA]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.SCCAT_AGENZIA]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.SCCAT_AGENZIA, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.SCCAT_AGENZIA)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.NTRAS_AGENZIA]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.NTRAS_AGENZIA]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.NTRAS_AGENZIA, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.NTRAS_AGENZIA)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.VICAT_AGENZIA]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.VICAT_AGENZIA]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.VICAT_AGENZIA, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.VICAT_AGENZIA)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.ATTSUC_AGENZIA]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.ATTSUC_AGENZIA]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.ATTSUC_AGENZIA, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.ATTSUC_AGENZIA)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.CONVED_AGENZIA]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.CONVED_AGENZIA]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.CONVED_AGENZIA, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.CONVED_AGENZIA)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.VISIP_AGENZIA]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.VISIP_AGENZIA]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.VISIP_AGENZIA, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.VISIP_AGENZIA)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.AALELO_AGENZIA]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.AALELO_AGENZIA]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.AALELO_AGENZIA, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.AALELO_AGENZIA)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.CDSC]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.CDSC]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.CDSC, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.CDSC)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.CCONDE]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.CCONDE]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.CCONDE, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.CCONDE)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.ALTRO_AGENZIA]}} <a href="javascript://" role="tooltip" aria-haspopup="true" class="tooltip tooltip-sm tooltip-top-right">
                                <fa-icon [icon]="faCircleInfo"></fa-icon>
                                <span class="tooltip-content">Se hai altri documenti e non sai dove inserirli, puoi usare questa sezione</span>
                            </a></p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.ALTRO_AGENZIA]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.ALTRO_AGENZIA, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.ALTRO_AGENZIA)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.CONCED_AGENZIA]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.CONCED_AGENZIA]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.CONCED_AGENZIA, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.CONCED_AGENZIA)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.PERCOS_AGENZIA]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.PERCOS_AGENZIA]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.PERCOS_AGENZIA, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.PERCOS_AGENZIA)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.REGCON_AGENZIA]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.REGCON_AGENZIA]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.REGCON_AGENZIA, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.REGCON_AGENZIA)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.AUTED_AGENZIA]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.AUTED_AGENZIA]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.AUTED_AGENZIA, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.AUTED_AGENZIA)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.FINLAV]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.FINLAV]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.FINLAV, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.FINLAV)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-container container-large mt-0">
            <h3 class="section-title"><fa-icon [icon]="faGraduationCap"></fa-icon> Documenti forniti da tecnico
                <a href="javascript://" role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg tooltip-top-left">
                    <fa-icon [icon]="faCircleInfo"></fa-icon>
                    <span class="tooltip-content">Ricorda che puoi caricare solo documenti in formato PDF</span>
                </a>
            </h3>
            <div class="content-padding">
                <div class="documenti-tecnico clr-row">
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.APE_TECNICO]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.APE_TECNICO]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button *ngIf="auth.isTecnico || auth.isAdmin" type="button"(click)="deleteDocumentoPratica(TipiDocumento.APE_TECNICO, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div *ngIf="auth.isTecnico || auth.isAdmin"><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.APE_TECNICO)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.SCCAT_TECNICO]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.SCCAT_TECNICO]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button *ngIf="auth.isTecnico || auth.isAdmin" type="button"(click)="deleteDocumentoPratica(TipiDocumento.SCCAT_TECNICO, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div *ngIf="auth.isTecnico || auth.isAdmin"><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.SCCAT_TECNICO)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.VICAT_TECNICO]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.VICAT_TECNICO]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button *ngIf="auth.isTecnico || auth.isAdmin" type="button"(click)="deleteDocumentoPratica(TipiDocumento.VICAT_TECNICO, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div *ngIf="auth.isTecnico || auth.isAdmin"><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.VICAT_TECNICO)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.VISIP_TECNICO]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.VISIP_TECNICO]; let i = index" class="documento-link">
                                <button *ngIf="auth.isTecnico || auth.isAdmin" type="button"(click)="deleteDocumentoPratica(TipiDocumento.VISIP_TECNICO, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div *ngIf="auth.isTecnico || auth.isAdmin"><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.VISIP_TECNICO)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.CONDED_TECNICO]}} </p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.CONDED_TECNICO]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button *ngIf="auth.isTecnico || auth.isAdmin" type="button"(click)="deleteDocumentoPratica(TipiDocumento.CONDED_TECNICO, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div *ngIf="auth.isTecnico || auth.isAdmin"><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.CONDED_TECNICO)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.VPRATE_TECNICO]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.VPRATE_TECNICO]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button *ngIf="auth.isTecnico || auth.isAdmin" type="button"(click)="deleteDocumentoPratica(TipiDocumento.VPRATE_TECNICO, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div *ngIf="auth.isTecnico || auth.isAdmin"><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.VPRATE_TECNICO)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.NPRATE_TECNICO]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.NPRATE_TECNICO]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button *ngIf="auth.isTecnico || auth.isAdmin" type="button"(click)="deleteDocumentoPratica(TipiDocumento.NPRATE_TECNICO, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div *ngIf="auth.isTecnico || auth.isAdmin"><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.NPRATE_TECNICO)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.NAGGCAT_TECNICO]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.NAGGCAT_TECNICO]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button *ngIf="auth.isTecnico || auth.isAdmin" type="button"(click)="deleteDocumentoPratica(TipiDocumento.NAGGCAT_TECNICO, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div *ngIf="auth.isTecnico || auth.isAdmin"><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.NAGGCAT_TECNICO)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.ALTRO_TECNICO]}} <a href="javascript://" role="tooltip" aria-haspopup="true" class="tooltip tooltip-sm tooltip-top-right">
                                <fa-icon [icon]="faCircleInfo"></fa-icon>
                                <span class="tooltip-content">Se hai altri documenti e non sai dove inserirli, puoi usare questa sezione</span>
                            </a></p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.ALTRO_TECNICO]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button *ngIf="auth.isTecnico || auth.isAdmin" type="button"(click)="deleteDocumentoPratica(TipiDocumento.ALTRO_TECNICO, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div *ngIf="auth.isTecnico || auth.isAdmin"><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.ALTRO_TECNICO)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.RELTEC_TECNICO]}} </p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.RELTEC_TECNICO]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button *ngIf="auth.isTecnico || auth.isAdmin" type="button"(click)="deleteDocumentoPratica(TipiDocumento.RELTEC_TECNICO, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div *ngIf="auth.isTecnico || auth.isAdmin"><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.RELTEC_TECNICO)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content content-anagrafica-vendita input-block" [ngClass]="{'active': activeTab == 1}">
       
        <div class="page-container container-large mt-0">
            <h3 class="section-title">Stai vendendo questo immobile
                <a href="javascript://" role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg tooltip-top-left">
                    <fa-icon [icon]="faCircleInfo"></fa-icon>
                    <span class="tooltip-content">I dati riportati in questa sezione saranno esclusivamente visibili alla tua agenzia e non al tecnico</span>
                </a>
            </h3>
            <div class="content-padding">
                <div class="documenti-vendita clr-row">
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.PACQ]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.PACQ]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.PACQ, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.PACQ)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.LTINC]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.LTINC]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.LTINC, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.LTINC)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.PROVV]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.PROVV]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.PROVV, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.PROVV)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.RICASS]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.RICASS]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.RICASS, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.RICASS)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.COMACC]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.COMACC]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.COMACC, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.COMACC)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.CPREL]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.CPREL]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.CPREL, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.CPREL)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.RRPREL]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.RRPREL]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.RRPREL, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.RRPREL)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.CALTR]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.CALTR]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.CALTR, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
<div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.CALTR)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="page-container container-large mt-0" *ngIf="!auth.isTecnico || prat.visibileAcquirenteAlTecnico">
            <h3 class="section-title">Acquirenti ({{acquirenti.length}})
                <a href="javascript://" role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg tooltip-top-left">
                    <fa-icon [icon]="faCircleInfo"></fa-icon>
                    <span class="tooltip-content">Ricordati di inserire sempre carta d'identità o passaporto e codice fiscale.</span>
                </a>
            </h3>
            <div class="content-padding">
                <div class="clr-form-control mt-0" *ngIf="!auth.isTecnico">
                    <div class="clr-control-container">
                        <div class="clr-checkbox-wrapper centered">
                            <input type="checkbox" clrCheckbox name="visibileAlTecnico" id="visibileAlTecnico" [(ngModel)]="prat.visibileAcquirenteAlTecnico"/>
                            <label for="visibileAlTecnico">Visibile al tecnico</label>
                        </div>
                    </div>
                </div>
                <div class="text-center"><button type="button"class="btn btn-link" (click)="addAcquirente()">Aggiungi acquirente</button></div>
                <div class="clr-row persona-row" *ngFor="let acquirente of acquirenti; let i = index" [attr.data-index]="i">
                    <div class="clr-col-md">
                        <clr-input-container>
                            <label>Nome e cognome</label>
                            <input clrInput type="text" [(ngModel)]="acquirenti[i].nome" name="acquirenti[{{i}}].nome" [attr.data-campo]="CampiForm.PERSONA_NOME + '-' + acquirente.id"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md">
                        <clr-input-container>
                            <label>Telefono</label>
                            <input clrInput type="text" [(ngModel)]="acquirenti[i].telefono" name="acquirenti[{{i}}].telefono" [attr.data-campo]="CampiForm.PERSONA_TELEFONO + '-' + acquirente.id"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md">
                        <clr-input-container>
                            <label>Email</label>
                            <input clrInput type="text" [(ngModel)]="acquirenti[i].email" name="acquirenti[{{i}}].email" [attr.data-campo]="CampiForm.PERSONA_EMAIL + '-' + acquirente.id"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-1 col-delete"><button type="button"class="btn btn-danger-outline btn-icon" (click)="deleteAcquirente(i)"><fa-icon [icon]="faTrashCan"></fa-icon></button></div>
                    <div class="clr-break-row"></div>
                    <div class="clr-col-12 clr-col-md col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.PR]}}</p>
                            <div *ngFor="let doc of acquirente.documentiIdentita; let i2 = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button" (click)="deleteDocumentoPersona('acquirente', TipiDocumento.PR, i, i2)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPersona('acquirente', TipiDocumento.PR, i)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-12 clr-col-md col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.CF]}}</p>
                            <div *ngFor="let doc of acquirente.documentiCF; let i2 = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPersona('acquirente', TipiDocumento.CF, i, i2)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPersona('acquirente', TipiDocumento.CF, i)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-12 clr-col-md col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.PRIV]}}</p>
                            <div *ngFor="let doc of acquirente.documentiPrivacy; let i2 = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPersona('acquirente', TipiDocumento.PRIV, i, i2)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPersona('acquirente', TipiDocumento.PRIV, i)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-1"></div>
                </div>
            </div>
        </div>
        
        <div class="page-container container-large mt-0">
            <h3 class="section-title">Documenti utili al rogito</h3>
            <div class="content-padding">
                <div class="clr-row">
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.CONSPE]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.CONSPE]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.CONSPE, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.CONSPE)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.LIBAMM]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.LIBAMM]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.LIBAMM, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.LIBAMM)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.CONIMP]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.CONIMP]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.CONIMP, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.CONIMP)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-lg-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.ROGALT]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.ROGALT]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.ROGALT, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.ROGALT)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-container container-large mt-0">
            <h3 class="section-title">Dati notaio</h3>
            <div class="content-padding">
                <h4>Notaio</h4>
                <div class="clr-row">
                    <div class="clr-col-md-4">
                        <clr-input-container>
                            <label>Nome e cognome</label>
                            <input clrInput type="text" [(ngModel)]="notaio.nome" name="notaioNome" [attr.data-campo]="CampiForm.PERSONA_NOME + '-' + notaio.id"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-4">
                        <clr-input-container>
                            <label>Telefono</label>
                            <input clrInput type="text" [(ngModel)]="notaio.telefono" name="telefonoNotaio" [attr.data-campo]="CampiForm.PERSONA_TELEFONO + '-' + notaio.id"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-4">
                        <clr-input-container>
                            <label>Email</label>
                            <input clrInput type="text" [(ngModel)]="notaio.email" name="emailNotaio" [attr.data-campo]="CampiForm.PERSONA_EMAIL + '-' + notaio.id"/>
                        </clr-input-container>
                    </div>
                </div>
                <h4>Referente notaio</h4>
                <div class="clr-row">
                    <div class="clr-col-md-4">
                        <clr-input-container>
                            <label>Nome e cognome</label>
                            <input clrInput type="text" [(ngModel)]="referenteNotaio.nome" name="referenteNotaioNome" [attr.data-campo]="CampiForm.PERSONA_NOME + '-' + referenteNotaio.id"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-4">
                        <clr-input-container>
                            <label>Telefono</label>
                            <input clrInput type="text" [(ngModel)]="referenteNotaio.telefono" name="telefonoReferenteNotaio" [attr.data-campo]="CampiForm.PERSONA_TELEFONO + '-' + referenteNotaio.id"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-4">
                        <clr-input-container>
                            <label>Email</label>
                            <input clrInput type="text" [(ngModel)]="referenteNotaio.email" name="emailReferenteNotaio" [attr.data-campo]="CampiForm.PERSONA_EMAIL + '-' + referenteNotaio.id"/>
                        </clr-input-container>
                    </div>
                </div>
                <div class="clr-row">
                    <div class="clr-col-md-4 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.DICAST]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.DICAST]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.DICAST, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.DICAST)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-4 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.ALTNOT]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.ALTNOT]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.ALTNOT, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.ALTNOT)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content content-anagrafica-vendita input-block" [ngClass]="{'active': activeTab == 2}">
        <div class="page-container container-large mt-0">
            <h3 class="section-title">Dati fornitura luce</h3>
            <div class="content-padding">
                <div class="text-right"><button type="button"class="btn btn-primary" (click)="inviaRichiestaVoltura()">Invia richiesta voltura luce</button></div>
                <p>In questa sezione puoi richiedere un cambio di anagrafica per luce oppure una nuova installazione.<br/>Seleziona cambio di anagrafica per fare una voltura oppure nuova richiesta per richiedere una nuova installazione</p>
                <clr-radio-container>
                    <clr-radio-wrapper>
                        <input type="radio" clrRadio name="volturaLuceNuova" [value]="false" [(ngModel)]="prat.volturaLuce_nuovaInstallazione" [attr.data-campo]="CampiForm.VOLTURALUCE_NUOVAINSTALLAZIONE" />
                        <label>Cambio di anagrafica <a href="javascript://" role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg tooltip-top-left">
                            <fa-icon [icon]="faCircleInfo"></fa-icon>
                            <span class="tooltip-content">Selezionando questa opzione richiedi una voltura, ovvero un cambio di anagrafica</span>
                        </a></label>
                    </clr-radio-wrapper>
                    <clr-radio-wrapper>
                        <input type="radio" clrRadio name="volturaLuceNuova" [value]="true" [(ngModel)]="prat.volturaLuce_nuovaInstallazione" [attr.data-campo]="CampiForm.VOLTURALUCE_NUOVAINSTALLAZIONE"/>
                        <label>Nuova installazione <a href="javascript://" role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg tooltip-top-left">
                            <fa-icon [icon]="faCircleInfo"></fa-icon>
                            <span class="tooltip-content">Selezionando questa opzione richiedi una nuova installazione ovvero aprire un nuovo contratto</span>
                        </a></label>
                    </clr-radio-wrapper>
                </clr-radio-container>
                <div class="clr-row">
                    <div class="clr-col-md-3">
                        <clr-radio-container clrInline>
                            <label>Residente</label>
                            <clr-radio-wrapper>
                                <input type="radio" clrRadio name="volturaLuceResidente" [value]="false" [(ngModel)]="prat.volturaLuce_residente" [attr.data-campo]="CampiForm.VOLTURALUCE_RESIDENTE"/>
                                <label>Si</label>
                            </clr-radio-wrapper>
                            <clr-radio-wrapper>
                                <input type="radio" clrRadio name="volturaLuceResidente" [value]="true" [(ngModel)]="prat.volturaLuce_residente" [attr.data-campo]="CampiForm.VOLTURALUCE_RESIDENTE" />
                                <label>No</label>
                            </clr-radio-wrapper>
                        </clr-radio-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-select-container>
                            <label>Potenza richiesta/Kw</label>
                            <select clrSelect [(ngModel)]="prat.volturaLuce_potenzaRichiesta" name="volturaLuce_potenzaRichiesta" [attr.data-campo]="CampiForm.VOLTURALUCE_POTENZARICHIESTA">
                                <option [value]="3">3 kw</option>
                                <option [value]="4.5">4,5 kw</option>
                                <option [value]="6">6 kw</option>
                            </select>
                        </clr-select-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Numero contatore POD</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaLuce_contatorePOD" name="volturaLuce_contatorePOD" [attr.data-campo]="CampiForm.VOLTURALUCE_CONTATOREPOD"/>
                        </clr-input-container>
                    </div>
                </div>
                <div class="clr-row">
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Denominazione società</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaLuce_denominazioneSocieta" name="volturaLuce_denominazioneSocieta" [attr.data-campo]="CampiForm.VOLTURALUCE_DENOMINAZIONESOCIETA"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Nome</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaLuce_nome" name="volturaLuce_nome" [attr.data-campo]="CampiForm.VOLTURALUCE_NOME"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Cognome</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaLuce_cognome" name="volturaLuce_cognome" [attr.data-campo]="CampiForm.VOLTURALUCE_COGNOME"/>
                        </clr-input-container>
                    </div>
                </div>
                <div class="clr-row">
                    <div class="clr-col-12 clr-col-md-3 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.CIVLUC]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.CIVLUC]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.CIVLUC, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.CIVLUC)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-12 clr-col-md-3 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.CFVLUC]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.CFVLUC]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.CFVLUC, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.CFVLUC)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-12 clr-col-md-3 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.UFTLUC]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.UFTLUC]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.UFTLUC, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.UFTLUC)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                </div>
                <div class="clr-row">
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Recapito telefonico</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaLuce_telefono" name="volturaLuce_telefono" [attr.data-campo]="CampiForm.VOLTURALUCE_TELEFONO"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Codice Fiscale</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaLuce_CF" name="volturaLuce_CF" [attr.data-campo]="CampiForm.VOLTURALUCE_CF"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Partita IVA</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaLuce_PIVA" name="volturaLuce_PIVA" [attr.data-campo]="CampiForm.VOLTURALUCE_PIVA"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-select-container>
                            <label>Destinazione immobile</label>
                            <select clrSelect [(ngModel)]="prat.volturaLuce_destinazioneImmobile" name="volturaLuce_destinazioneImmobile" [attr.data-campo]="CampiForm.VOLTURALUCE_DESTINAZIONEIMMOBILE">
                                <option [ngValue]="DestinazioneImmobile.ABITAZIONE">ABITAZIONE</option>
                                <option [ngValue]="DestinazioneImmobile.UFFICIO">UFFICIO</option>
                                <option [ngValue]="DestinazioneImmobile.NEGOZIO">NEGOZIO</option>
                                <option [ngValue]="DestinazioneImmobile.LABORATORIO">LABORATORIO</option>
                            </select>
                        </clr-select-container>
                    </div>
                </div>
                <div class="clr-row">
                    <div class="clr-col-12">
                        <clr-input-container>
                            <label>Indirizzo</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaLuce_indirizzo" name="volturaLuce_indirizzo" [attr.data-campo]="CampiForm.VOLTURALUCE_INDIRIZZO"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>CAP</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaLuce_CAP" name="volturaLuce_CAP" [attr.data-campo]="CampiForm.VOLTURALUCE_CAP"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Citta</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaLuce_citta" name="volturaLuce_citta" [attr.data-campo]="CampiForm.VOLTURALUCE_CITTA"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Provincia</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaLuce_provincia" name="volturaLuce_provincia" [attr.data-campo]="CampiForm.VOLTURALUCE_PROVINCIA"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Regione</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaLuce_regione" name="volturaLuce_regione" [attr.data-campo]="CampiForm.VOLTURALUCE_REGIONE"/>
                        </clr-input-container>
                    </div>
                </div>
                <h4>Informazioni di pagamento e recapito fattura</h4>
                <div class="clr-row">
                    <div class="clr-col-md-3">
                        <clr-select-container>
                            <label>Modalità recapito fattura</label>
                            <select clrSelect [(ngModel)]="prat.volturaLuce_modalitaRecapitoFattura" name="volturaLuce_modalitaRecapitoFattura" [attr.data-campo]="CampiForm.VOLTURALUCE_MODALITARECAPITOFATTURA">
                                <option [ngValue]="false">POSTA</option>
                                <option [ngValue]="true">EMAIL</option>
                            </select>
                        </clr-select-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Email recapito bolletta/fattura</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaLuce_emailRecapitoBollettaFattura" name="volturaLuce_emailRecapitoBollettaFattura" [attr.data-campo]="CampiForm.VOLTURALUCE_EMAILRECAPITOBOLLETTAFATTURA"/>
                        </clr-input-container>
                    </div>
                </div>
                <div class="clr-row">
                    <div class="clr-col-md-3">
                        <clr-select-container>
                            <label>Modalità pagamento fattura</label>
                            <select clrSelect [(ngModel)]="prat.volturaLuce_modalitaPagamentoFattura" name="volturaLuce_modalitaPagamentoFattura" [attr.data-campo]="CampiForm.VOLTURALUCE_MODALITAPAGAMENTOFATTURA">
                                <option [ngValue]="true">RID</option>
                                <option [ngValue]="false">BOLLETTINO POSTALE</option>
                            </select>
                        </clr-select-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Intestatario conto corrente</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaLuce_intestatarioConto" name="volturaLuce_intestatarioConto" [attr.data-campo]="CampiForm.VOLTURALUCE_INTESTATARIOCONTO"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Banca</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaLuce_banca" name="volturaLuce_banca" [attr.data-campo]="CampiForm.VOLTURALUCE_BANCA"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>IBAN</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaLuce_IBAN" name="volturaLuce_IBAN" [attr.data-campo]="CampiForm.VOLTURALUCE_IBAN"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>SWIFT</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaLuce_swift" name="volturaLuce_swift" [attr.data-campo]="CampiForm.VOLTURALUCE_SWIFT"/>
                        </clr-input-container>
                    </div>
                </div>
                <h4>Dati di residenza (compilare solo se diversi dai dati di fornitura)</h4>
                <div class="clr-row">
                    <div class="clr-col-12">
                        <clr-input-container>
                            <label>Indirizzo</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaLuce_residenza_indirizzo" name="volturaLuce_residenza_indirizzo" [attr.data-campo]="CampiForm.VOLTURALUCE_RESIDENZA_INDIRIZZO"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-2">
                        <clr-input-container>
                            <label>CAP</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaLuce_residenza_CAP" name="volturaLuce_residenza_CAP" [attr.data-campo]="CampiForm.VOLTURALUCE_RESIDENZA_CAP"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-2">
                        <clr-input-container>
                            <label>Citta</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaLuce_residenza_citta" name="volturaLuce_residenza_citta" [attr.data-campo]="CampiForm.VOLTURALUCE_RESIDENZA_CITTA"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-2">
                        <clr-input-container>
                            <label>Provincia</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaLuce_residenza_provincia" name="volturaLuce_residenza_provincia" [attr.data-campo]="CampiForm.VOLTURALUCE_RESIDENZA_PROVINCIA"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-2">
                        <clr-input-container>
                            <label>Regione</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaLuce_residenza_regione" name="volturaLuce_residenza_regione" [attr.data-campo]="CampiForm.VOLTURALUCE_RESIDENZA_REGIONE"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-2">
                        <clr-input-container>
                            <label>Stato</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaLuce_residenza_stato" name="volturaLuce_residenza_stato" [attr.data-campo]="CampiForm.VOLTURALUCE_RESIDENZA_STATO"/>
                        </clr-input-container>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-container container-large mt-0">
            <h3 class="section-title">Dati fornitura gas</h3>
            <div class="content-padding">
                <div class="text-right"><button type="button"class="btn btn-primary">Invia richiesta voltura gas</button></div>
                <p>In questa sezione puoi richiedere un cambio di anagrafica per luce oppure una nuova installazione.<br/>Seleziona cambio di anagrafica per fare una voltura oppure nuova richiesta per richiedere una nuova installazione</p>
                <clr-radio-container>
                    <clr-radio-wrapper>
                        <input type="radio" clrRadio name="volturaGas_nuovaInstallazione" [value]="false" [(ngModel)]="prat.volturaGas_nuovaInstallazione" [attr.data-campo]="CampiForm.VOLTURAGAS_NUOVAINSTALLAZIONE"/>
                        <label>Cambio di anagrafica <a href="javascript://" role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg tooltip-top-left">
                            <fa-icon [icon]="faCircleInfo"></fa-icon>
                            <span class="tooltip-content">Selezionando questa opzione richiedi una voltura, ovvero un cambio di anagrafica</span>
                        </a></label>
                    </clr-radio-wrapper>
                    <clr-radio-wrapper>
                        <input type="radio" clrRadio name="volturaGas_nuovaInstallazione" [value]="true" [(ngModel)]="prat.volturaGas_nuovaInstallazione" [attr.data-campo]="CampiForm.VOLTURAGAS_NUOVAINSTALLAZIONE"/>
                        <label>Nuova installazione <a href="javascript://" role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg tooltip-top-left">
                            <fa-icon [icon]="faCircleInfo"></fa-icon>
                            <span class="tooltip-content">Selezionando questa opzione richiedi una nuova installazione ovvero aprire un nuovo contratto</span>
                        </a></label>
                    </clr-radio-wrapper>
                </clr-radio-container>
                <div class="clr-row">
                    <div class="clr-col-md-3">
                        <clr-radio-container clrInline>
                            <label>Residente</label>
                            <clr-radio-wrapper>
                                <input type="radio" clrRadio name="volturaGas_residente" [value]="false" [(ngModel)]="prat.volturaGas_residente" [attr.data-campo]="CampiForm.VOLTURAGAS_RESIDENTE"/>
                                <label>Si</label>
                            </clr-radio-wrapper>
                            <clr-radio-wrapper>
                                <input type="radio" clrRadio name="volturaGas_residente" [value]="true" [(ngModel)]="prat.volturaGas_residente" [attr.data-campo]="CampiForm.VOLTURAGAS_RESIDENTE"/>
                                <label>No</label>
                            </clr-radio-wrapper>
                        </clr-radio-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-select-container>
                            <label>Destinazione Gas 
                                <a href="javascript://" role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg tooltip-top-left">
                                    <fa-icon [icon]="faCircleInfo"></fa-icon>
                                    <span class="tooltip-content">Puoi selezionare contemporaneamente più selezioni di uso del gas</span>
                                </a>
                            </label>
                            <select clrSelect [(ngModel)]="prat.volturaGas_destinazioneGas" name="volturaGas_destinazioneGas" [attr.data-campo]="CampiForm.VOLTURAGAS_DESTINAZIONEGAS">
                                <option value="cottura">Cottura</option>
                                <option value="riscaldamento">Riscaldamento</option>
                                <option value="acquacaldasanitaria">Acqua calda sanitaria</option>
                            </select>
                        </clr-select-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Numero contatore PDR</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaGas_contatorePDR" name="volturaGas_contatorePDR" [attr.data-campo]="CampiForm.VOLTURAGAS_CONTATOREPDR"/>
                        </clr-input-container>
                    </div>
                </div>
                <div class="clr-row">
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Denominazione società</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaGas_denominazioneSocieta" name="volturaGas_denominazioneSocieta" [attr.data-campo]="CampiForm.VOLTURAGAS_DENOMINAZIONESOCIETA"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Nome</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaGas_nome" name="volturaGas_nome" [attr.data-campo]="CampiForm.VOLTURAGAS_NOME"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Cognome</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaGas_cognome" name="volturaGas_cognome" [attr.data-campo]="CampiForm.VOLTURAGAS_COGNOME"/>
                        </clr-input-container>
                    </div>
                </div>
                <div class="clr-row">
                    <div class="clr-col-12 clr-col-md-3 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.CIVGAS]}} </p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.CIVGAS]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.CIVGAS, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.CIVGAS)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-12 clr-col-md-3 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.CFVGAS]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.CFVGAS]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.CFVGAS, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.CFVGAS)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-12 clr-col-md-3 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">{{TipiLabel[TipiDocumento.UFTGAS]}}</p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.UFTGAS]; let i = index" class="documento-link" [attr.data-campo]="CampiForm.DOCUMENTO + '-' + doc.id">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.UFTGAS, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.UFTGAS)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                </div>
                <div class="clr-row">
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Recapito telefonico</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaGas_telefono" name="volturaGas_telefono" [attr.data-campo]="CampiForm.VOLTURAGAS_TELEFONO"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Codice Fiscale</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaGas_CF" name="volturaGas_CF" [attr.data-campo]="CampiForm.VOLTURAGAS_CF"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Partita IVA</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaGas_PIVA" name="volturaGas_PIVA" [attr.data-campo]="CampiForm.VOLTURAGAS_PIVA"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-select-container>
                            <label>Destinazione immobile</label>
                            <select clrSelect [(ngModel)]="prat.volturaGas_destinazioneImmobile" name="volturaGas_destinazioneImmobile" [attr.data-campo]="CampiForm.VOLTURAGAS_DESTINAZIONEIMMOBILE">
                                <option [ngValue]="DestinazioneImmobile.ABITAZIONE">ABITAZIONE</option>
                                <option [ngValue]="DestinazioneImmobile.UFFICIO">UFFICIO</option>
                                <option [ngValue]="DestinazioneImmobile.NEGOZIO">NEGOZIO</option>
                                <option [ngValue]="DestinazioneImmobile.LABORATORIO">LABORATORIO</option>
                            </select>
                        </clr-select-container>
                    </div>
                </div>
                <div class="clr-row">
                    <div class="clr-col-12">
                        <clr-input-container>
                            <label>Indirizzo</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaGas_indirizzo" name="volturaGas_indirizzo" [attr.data-campo]="CampiForm.VOLTURAGAS_INDIRIZZO"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>CAP</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaGas_CAP" name="volturaGas_CAP" [attr.data-campo]="CampiForm.VOLTURAGAS_CAP"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Citta</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaGas_citta" name="volturaGas_citta" [attr.data-campo]="CampiForm.VOLTURAGAS_CITTA"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Provincia</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaGas_provincia" name="volturaGas_provincia" [attr.data-campo]="CampiForm.VOLTURAGAS_PROVINCIA"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Regione</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaGas_regione" name="volturaGas_regione" [attr.data-campo]="CampiForm.VOLTURAGAS_REGIONE"/>
                        </clr-input-container>
                    </div>
                </div>
                <h4>Informazioni di pagamento e recapito fattura</h4>
                <div class="clr-row">
                    <div class="clr-col-md-3">
                        <clr-select-container>
                            <label>Modalità recapito fattura</label>
                            <select clrSelect [(ngModel)]="prat.volturaGas_modalitaRecapitoFattura" name="volturaGas_modalitaRecapitoFattura" [attr.data-campo]="CampiForm.VOLTURAGAS_MODALITARECAPITOFATTURA">
                                <option [ngValue]="false">POSTA</option>
                                <option [ngValue]="true">EMAIL</option>
                            </select>
                        </clr-select-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Email recapito bolletta/fattura</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaGas_emailRecapitoBollettaFattura" name="volturaGas_emailRecapitoBollettaFattura" [attr.data-campo]="CampiForm.VOLTURAGAS_EMAILRECAPITOBOLLETTAFATTURA"/>
                        </clr-input-container>
                    </div>
                </div>
                <div class="clr-row">
                    <div class="clr-col-md-3">
                        <clr-select-container>
                            <label>Modalità pagamento fattura</label>
                            <select clrSelect [(ngModel)]="prat.volturaGas_modalitaPagamentoFattura" name="volturaGas_modalitaPagamentoFattura" [attr.data-campo]="CampiForm.VOLTURAGAS_MODALITAPAGAMENTOFATTURA">
                                <option [ngValue]="true">RID</option>
                                <option [ngValue]="false">BOLLETTINO POSTALE</option>
                            </select>
                        </clr-select-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Intestatario conto corrente</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaGas_intestatarioConto" name="volturaGas_intestatarioConto" [attr.data-campo]="CampiForm.VOLTURAGAS_INTESTATARIOCONTO"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Banca</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaGas_banca" name="volturaGas_banca" [attr.data-campo]="CampiForm.VOLTURAGAS_BANCA"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>IBAN</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaGas_IBAN" name="volturaGas_IBAN" [attr.data-campo]="CampiForm.VOLTURAGAS_IBAN"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>SWIFT</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaGas_swift" name="volturaGas_swift" [attr.data-campo]="CampiForm.VOLTURAGAS_SWIFT"/>
                        </clr-input-container>
                    </div>
                </div>
                <h4>Dati di residenza (compilare solo se diversi dai dati di fornitura)</h4>
                <div class="clr-row">
                    <div class="clr-col-12">
                        <clr-input-container>
                            <label>Indirizzo</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaGas_residenza_indirizzo" name="volturaGas_residenza_indirizzo" [attr.data-campo]="CampiForm.VOLTURAGAS_RESIDENZA_INDIRIZZO"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-2">
                        <clr-input-container>
                            <label>CAP</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaGas_residenza_CAP" name="volturaGas_residenza_CAP" [attr.data-campo]="CampiForm.VOLTURAGAS_RESIDENZA_CAP"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-2">
                        <clr-input-container>
                            <label>Citta</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaGas_residenza_citta" name="volturaGas_residenza_citta" [attr.data-campo]="CampiForm.VOLTURAGAS_RESIDENZA_CITTA"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-2">
                        <clr-input-container>
                            <label>Provincia</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaGas_residenza_provincia" name="volturaGas_residenza_provincia" [attr.data-campo]="CampiForm.VOLTURAGAS_RESIDENZA_PROVINCIA"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-2">
                        <clr-input-container>
                            <label>Regione</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaGas_residenza_regione" name="volturaGas_residenza_regione" [attr.data-campo]="CampiForm.VOLTURAGAS_RESIDENZA_REGIONE"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-2">
                        <clr-input-container>
                            <label>Stato</label>
                            <input clrInput type="text" [(ngModel)]="prat.volturaGas_residenza_stato" name="volturaGas_residenza_stato" [attr.data-campo]="CampiForm.VOLTURAGAS_RESIDENZA_STATO"/>
                        </clr-input-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content content-invio-files input-block" [ngClass]="{'active': activeTab == 3}">
        <div class="page-container container-large mt-0">
            <h3 class="section-title">Invia file pratica</h3>
            <div class="content-padding">
                <ng-container *ngIf="edit && !formDirty">
                    <div class="text-right"><button type="button"class="btn btn-primary" (click)="inviaFilePratica()">Invia file pratica</button></div>
                    <div>
                        <clr-textarea-container>
                            <label>Testo email</label>
                            <textarea clrTextarea [(ngModel)]="invioMailTesto" name="invioMailTesto"></textarea>
                        </clr-textarea-container>
                    </div>
                    <div>
                        <label>Documenti da inviare</label>
                        <ng-select
                        [items]="valoriDocumentiDaInviare"
                        bindLabel="name"
                        appendTo="body"
                        [multiple]="true"
                        [(ngModel)]="selectedDocumentiDaInviare"
                        name="documentiDaInviare">
                        </ng-select>
                    </div>
                    <div>
                        <clr-input-container>
                            <label>Indirizzi email a cui inviare i file
                                <a href="javascript://" role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg tooltip-top-left">
                                    <fa-icon [icon]="faCircleInfo"></fa-icon>
                                    <span class="tooltip-content">Inserire gli indirizzi mail separati da una virgola o da un punto e virgola</span>
                                </a>
                            </label>
                            <input clrInput type="text" [(ngModel)]="invioMailIndirizzo" name="invioMailIndirizzo"/>
                        </clr-input-container>
                    </div>
                </ng-container>
                <ng-container *ngIf="!edit || formDirty">
                    <h2 class="text-center">Prima di inviare i file devi salvare la pratica.</h2>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="content content-invio-files input-block" [ngClass]="{'active': activeTab == 4}">
        <div class="page-container container-large mt-0">
            <h3 class="section-title">Brochure</h3>
            <div class="content-padding">
                <div class="text-right"><button type="button"class="btn btn-primary" (click)="generaBrochure()">Genera brochure</button></div>
                <div class="clr-row">
                    <div class="clr-col-12">
                        <clr-textarea-container>
                            <label>Testo brochure
                                <a href="javascript://" role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg tooltip-top-right">
                                    <fa-icon [icon]="faCircleInfo"></fa-icon>
                                    <span class="tooltip-content">Per una resa grafica migliore, ti consigliamo di andare a capo dopo i punti di punteggiatura.</span>
                                </a>
                            </label>
                            <textarea clrTextarea [(ngModel)]="prat.brochureDescrizione" name="brochureDescrizione"></textarea>
                        </clr-textarea-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-date-container>
                            <label>Data annuncio</label>
                            <input type="date" autocomplete="off" clrDate name="dataAnnuncio" [(ngModel)]="prat.dataAnnuncio" placeholder="GG/MM/AAAA" maxlength="10" />
                        </clr-date-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Titolo brochure</label>
                            <input clrInput type="text" [(ngModel)]="prat.brochureTitolo" name="brochureTitolo"/>
                        </clr-input-container>
                    </div>
                </div>
                <div class="clr-row">
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Prezzo immobile</label>
                            <input clrInput type="number" [(ngModel)]="prat.prezzo" name="prezzo"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Spese condominiali</label>
                            <input clrInput type="number" [(ngModel)]="prat.speseCondominiali" name="speseCondominiali"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Categoria catastale</label>
                            <input clrInput type="text" [(ngModel)]="prat.categoriaCatastale" name="categoriaCatastale"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Rendita catastale</label>
                            <input clrInput type="number" [(ngModel)]="prat.renditaCatastale" name="renditaCatastale"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Superficie netta Mq</label>
                            <input clrInput type="number" [(ngModel)]="prat.superficieNetta" name="superficieNetta"/>
                        </clr-input-container>
                    </div>
                    <div class="clr-col-md-3">
                        <clr-input-container>
                            <label>Superficie commerciale Mq</label>
                            <input clrInput type="number" [(ngModel)]="prat.superficieCommerciale" name="superficieCommerciale"/>
                        </clr-input-container>
                    </div>
                </div>
                <div class="clr-row">
                    <div class="clr-col-md-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">
                                {{TipiLabel[TipiDocumento.COPBRC]}} 
                                <a href="javascript://" role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg tooltip-top-left">
                                    <fa-icon [icon]="faCircleInfo"></fa-icon>
                                    <span class="tooltip-content">Caricare solo un'immagine in formato jpg o png</span>
                                </a>
                            </p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.COPBRC]; let i = index" class="documento-link">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.COPBRC, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.COPBRC)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">
                                {{TipiLabel[TipiDocumento.IMGS]}} 
                                <a href="javascript://" role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg tooltip-top-left">
                                    <fa-icon [icon]="faCircleInfo"></fa-icon>
                                    <span class="tooltip-content">Puoi caricare fino a 4 foto. Sono consigliate foto orizzontali e non verticali. Caricare immagini in formato jpg o png</span>
                                </a>
                            </p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.IMGS]; let i = index" class="documento-link">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.IMGS, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.IMGS)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">
                                {{TipiLabel[TipiDocumento.PLANIM]}} 
                                <a href="javascript://" role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg tooltip-top-left">
                                    <fa-icon [icon]="faCircleInfo"></fa-icon>
                                    <span class="tooltip-content">Caricare documenti in formato PDF</span>
                                </a>
                            </p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.PLANIM]; let i = index" class="documento-link">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.PLANIM, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.PLANIM)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                    <div class="clr-col-md-3 clr-col-12 col-documenti">
                        <div class="col-documenti-inner">
                            <p class="documento-label">
                                {{TipiLabel[TipiDocumento.PLANQU]}}
                                <a href="javascript://" role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg tooltip-top-left">
                                    <fa-icon [icon]="faCircleInfo"></fa-icon>
                                    <span class="tooltip-content">Caricare documenti in formato PDF</span>
                                </a>
                            </p>
                            <div *ngFor="let doc of documentiPratica[TipiDocumento.PLANQU]; let i = index" class="documento-link">
                                <button type="button"(click)="deleteDocumentoPratica(TipiDocumento.PLANQU, i)" class="btn btn-link btn-danger">Rimuovi</button><a (click)="getDocumento(doc)" target="_blank">{{doc.filename}}</a>
                                <p class="oraData">{{doc.dataCaricamento}} {{doc.oraCaricamento}}</p>
                            </div>
                            <div><button class="btn btn-link" (click)="aggiungiDocumentoPratica(TipiDocumento.PLANQU)"><fa-icon [icon]="faPlus" ></fa-icon> Carica documento</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content content-chat input-block" [ngClass]="{'active': activeTab == 5}">
        <div class="page-container container-large mt-0">
            <h3 class="section-title">
                <span class="left-name">{{ auth.isTecnico ? "Agenzia" : "Tecnico"}}</span>
                Chat
                <span class="right-name">{{ auth.isTecnico ? "Tecnico" : "Agenzia"}}</span>
            </h3>
            <div class="chat">
                <div class="messaggi pl-2 pr-2">
                    <div class="mex" *ngFor="let mex of prat.messaggi" [ngClass]="{'destra': (auth.isTecnico && mex.inviatoDaTecnico) || (!auth.isTecnico && !mex.inviatoDaTecnico)}">
                        <div class="avatar"><fa-icon [icon]="faUser"></fa-icon></div>
                        <div class="fumetto"><p>{{ mex.messaggio }}</p><p class="time">{{ mex.dataOra }}</p></div>
                    </div>
                </div>
                <div class="invio pl-2 pr-2">
                    <div>
                        <clr-textarea-container>
                            <textarea clrTextarea [(ngModel)]="messaggioChat" name="messaggioChat"></textarea>
                        </clr-textarea-container>
                    </div>
                    <div class="text-right">
                        <button type="button" class="btn btn-primary" (click)="inviaMessaggio()">Invia</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="hidden-input-file" style="display: none;"></div>
    <div class="text-right pr-1 mb-2">
        <button type="button"class="btn btn-primary" (click)="submit()">Salva</button>
        <button type="button"class="btn" (click)="annulla()">Annulla</button>
    </div>
</form>

<clr-modal [(clrModalOpen)]="showModalRogito">
    <h3 class="modal-title text-center">Non hai il rogito?</h3>
    <div class="modal-body">
        <h4 class="text-align-center">Vuoi incaricare il tecnico per il suo recupero? Scrivi in chat maggiori informazioni.</h4>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" type="button" (click)="rogitoChat()">Scrivi in chat</button>
        <button class="btn btn-outline" type="button" (click)="showModalRogito = false;">Annulla</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showModalConsulente">
    <h3 class="modal-title text-center">Dati consulente</h3>
    <div class="modal-body">
        <p><strong>Nome e cognome:</strong> {{datiModalConsulente.nome}}</p>
        <p><strong>Email:</strong> {{datiModalConsulente.email}}</p>
        <p><strong>Telefono:</strong> {{datiModalConsulente.telefono}}</p>
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline" type="button" (click)="showModalConsulente = false;">Chiudi</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showModalAlertTecnico">
    <h3 class="modal-title text-center">Assenza di un tecnico</h3>
    <div class="modal-body">
        <p>Caro utente, la tua pratica risulta inserita correttamente</p>
        <p>Ricordati pero' che non hai il tecnico di fiducia associato al tuo team.<br/>
            Per questo motivo contatta l'assistenza di PraticaOk al numero 351/6027476 o via mail a info@praticaok.com</p>
        <p>Grazie per la collaborazione!<br/>
        Lo Staff di PraticaOk</p>
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline" type="button" (click)="showModalAlertTecnico = false;">Ok</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showModalTecnicoConfirm">
    <h3 class="modal-title text-center">Conferma tecnico</h3>
    <div class="modal-body">
        <p>La pratica che stai creando verrà associata al tecnico {{tecnicoSelected.nome}} ma ne hai altri disponibili.</p>
        <p>Confermi di voler assegnare la pratica a questo tecnico?</p>
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline" type="button" (click)="showModalTecnicoConfirm = false;">Annulla</button>
        <button class="btn btn-primary" type="button" (click)="submit(true)">Conferma</button>
    </div>
</clr-modal>