import { Albo } from "../const/user-const";
import { AnagraficaSocieta } from "./anagrafica-societa";

export class AnagraficaUtente {
    id: string = "";
    nome: string = "";
    email: string = "";
    telefono: string = "";
    password: string = "";
    ruolo: string = "";
    active: boolean = false;
    emailCC: string = "";
    interno: boolean = false;
    dataAccettazioneCondizioni: string = "";
    societa: AnagraficaSocieta[] = [];

    tecnico_luogoNascita: string = "";
    tecnico_provinciaNascita: string = "";
    tecnico_cf: string = "";
    tecnico_piva: string = "";
    tecnico_codiceUnivoco: string = "";
    tecnico_pec: string = "";
    tecnico_provincia_albo: string = "";
    tecnico_albo: Albo = Albo.GEOMETRA;
    tecnico_numero_iscrizione_albo: string = "";
    tecnico_sede_cap: string = "";
    tecnico_sede_localita: string = "";
    tecnico_sede_provincia: string = "";
    tecnico_sede_telefono: string = "";
    tecnico_sede_cellulare: string = "";
    tecnico_sede_indirizzo: string = "";
    tecnico_dataNascita: string = "";
    tecnico_interno: boolean = false;
    tecnico_esterno_spa: boolean = false;
    accettatoDaStudio: boolean = false;
    notaAmministratore: string = "";
    
    societaId: number = 0;
}