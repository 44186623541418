import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-lp-template',
  templateUrl: './lp-template.component.html',
  styleUrls: ['./lp-template.component.scss']
})
export class LpTemplateComponent {
  @Input() size: string = "normal";


}
