import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faCircleXmark, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { faCircleInfo, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { UserRoles } from 'src/app/const/user-roles';
import { AnagraficaSocieta } from 'src/app/models/anagrafica-societa';
import { AnagraficaSocietaCompleta } from 'src/app/models/anagrafica-societa-completa';
import { AnagraficaUtente } from 'src/app/models/anagrafica-utente';
import { AssegnaTecnico } from 'src/app/models/assegna-tecnico';
import { InvitoTecnico } from 'src/app/models/invito-tecnico';
import { AuthService } from 'src/app/services/auth.service';
import { SocietaService } from 'src/app/services/societa.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-anagrafica-societa',
  templateUrl: './anagrafica-societa.component.html',
  styleUrls: ['./anagrafica-societa.component.scss']
})
export class AnagraficaSocietaComponent {
  model: AnagraficaSocietaCompleta = new AnagraficaSocietaCompleta();
  loading: boolean = false;
  logoSrc: string = "";
  showModalInvitoTecnico = false;
  showModalRimuoviTecnico = false;
  showModalInfoTecnico = false;
  infoTecnico: AnagraficaUtente = new AnagraficaUtente();
  modelInvitoTecnico: InvitoTecnico = new InvitoTecnico();
  modelAssegnaTecnico: AssegnaTecnico = new AssegnaTecnico();
  tecnicoRimozioneId: string = "";
  nuovoTecnicoRiassegnazione: string = "";
  mostraTecniciInterni: boolean = false;
  showDisabilitati: boolean = false;
  faCircleXmark = faCircleXmark;
  faPenToSquare = faPenToSquare;
  faCircleInfo = faCircleInfo;
  faPlus = faPlus;

  constructor(private societaSrv: SocietaService, private spinner: SpinnerService, private toastr: ToastrService, private route: ActivatedRoute, public auth: AuthService, private router: Router) {}

  ngOnInit() {
    this.loading = true;
    this.getPageData();
  }

  getPageData() {
    var id = parseInt(this.route.snapshot.paramMap.get('id') ?? "0");
    this.spinner.show();
    if(id == 0) {
      this.societaSrv.getByUser().then((res) => {
        this.model = res;
        this.loading = false;
        this.logoSrc = environment.API_URL + "loghiSocieta/" + res.societa.id + ".png";
        this.showDisabilitati = this.model.listaUtenti.filter((user) => {return !user.active}).length > 0;
      }).catch(() => {
        this.toastr.error("C'è stato un errore, se si ripete, contatta l'assistenza.");
        this.loading = false;
      }).finally(() => {this.spinner.dismiss();});
    } else {
      if(!this.auth.isAdmin && !this.auth.isTeamLeader) {
        this.router.navigate([""]);
      }
      if(this.auth.isAdmin) {
        this.societaSrv.getByIdAdmin(id).then((res) => {
          this.model = res;
          this.loading = false;
          this.logoSrc = environment.API_URL + "loghiSocieta/" + res.societa.id + ".png";
          this.showDisabilitati = this.model.listaUtenti.filter((user) => {return !user.active}).length > 0;
        }).catch(() => {
          this.toastr.error("C'è stato un errore, se si ripete, contatta l'assistenza.");
          this.loading = false;
        }).finally(() => {this.spinner.dismiss();});
      } else if(this.auth.isTeamLeader) {
        this.societaSrv.getCompletaById(id).then((res) => {
          this.model = res;
          this.loading = false;
          this.logoSrc = environment.API_URL + "loghiSocieta/" + res.societa.id + ".png";
          this.showDisabilitati = this.model.listaUtenti.filter((user) => {return !user.active}).length > 0;
        }).catch(() => {
          this.toastr.error("C'è stato un errore, se si ripete, contatta l'assistenza.");
          this.loading = false;
        }).finally(() => {this.spinner.dismiss();});
      }
      
    }
  }

  save() {
    if(!this.loading) {
      this.spinner.show();
      this.societaSrv.edit(this.model.societa).then(() => {
        this.toastr.success("Anagrafica salvata con successo");
        this.spinner.dismiss();
      }).catch((err: any) => {
        this.toastr.error("C'è stato un errore con il salvataggio dell'anagrafica.");
        this.spinner.dismiss();
      });
    }
  }

  changeFileLogo(event: Event) {
    const target = event.target as HTMLInputElement;
    const reader = new FileReader();
    if(target.files && target.files.length) {
      const imgfile = target.files[0];
      reader.readAsDataURL(imgfile);
      reader.onload = async () => {
        const image = new Image();
        image.onload = () => {
          var rapporto = image.width/image.height;
          var newWidth = 300;
          var newHeight = newWidth/rapporto;
          const canvas = document.createElement('canvas');
          canvas.width = newWidth;
          canvas.height = newHeight;
          const ctx = canvas.getContext('2d');
          if (ctx != null) {
            ctx.drawImage(image, 0, 0, newWidth, newHeight);
          }
          this.model.societa.logo = canvas.toDataURL('image/png', 1);
          this.logoSrc = this.model.societa.logo;
        };
        image.src = reader.result as string;
      };
    }
  }

  displayModalRimuoviTecnico(tecnicoId: string) {
    this.tecnicoRimozioneId = tecnicoId;
    this.nuovoTecnicoRiassegnazione = "";
    this.showModalRimuoviTecnico = true;
  }

  displayModalInfoTecnico(tec: AnagraficaUtente) {
    this.infoTecnico = tec;
    if(tec.tecnico_interno) {
      tec.email = "info@capassodantone.it";
      tec.tecnico_sede_telefono = "0245495197";
    }
    this.showModalInfoTecnico = true;
  }

  rimuoviTecnico() {
    this.spinner.show();
    this.societaSrv.rimuoviTecnico(this.tecnicoRimozioneId, this.nuovoTecnicoRiassegnazione, this.auth.isAdmin ? this.model.societa.id : 0).then((res: string) => {
      if(res == "") {
        this.toastr.success("Il tecnico è stato rimosso");
      } else if(res == "riassegnazione_ok") {
        this.toastr.success("Il tecnico è stato rimosso e le pratiche riassegnate a quello da te indicato");
      } else if(res == "riassegnazione_error") {
        this.toastr.success("Il tecnico è stato rimosso ma non siamo riusciti a riassegnare le pratiche a quello nuovo. Dovrai farlo a mano.");
      } else {
        this.toastr.success("Il tecnico è stato rimosso");
      }
      this.showModalRimuoviTecnico = false;
      this.tecnicoRimozioneId = "";
      this.nuovoTecnicoRiassegnazione = "";
      this.spinner.dismiss();
      this.getPageData();
    }).catch((err) => {
      this.toastr.error("C'è stato un errore con la rimozione del tecnico");
      this.spinner.dismiss();
    });
  }

  invitaTecnico() {
    if(!confirm("Confermi di voler invitare il tecnico?")) {
      return;
    }
    if(this.auth.isAdmin) {
      this.modelInvitoTecnico.societaId = this.model.societa.id;
    }
    this.spinner.show();
    this.societaSrv.invitaTecnico(this.modelInvitoTecnico).then((res: string) => {
      if(res == "invite_sent") {
        this.toastr.success("Il tecnico ha ricevuto la mail di invito");
      } else if(res == "tecnico_added") {
        this.toastr.success("Il tecnico è stato associato"+ (this.auth.isAdmin ? " all'" : " alla tua ") +"agenzia");
      } else if(res == "tecnico_added_ok_pratiche_precedenti") {
        this.toastr.success("Il tecnico è stato associato"+ (this.auth.isAdmin ? " all'" : " alla tua ") +"agenzia e le pratiche precedenti gli sono state assegnate");
      } else if(res == "tecnico_added_error_pratiche_precedenti") {
        this.toastr.success("Il tecnico è stato associato"+ (this.auth.isAdmin ? " all'" : " alla tua ") +"agenzia ma c'è stato un errore nell'assegnazione delle pratiche precedenti. Dovrai assegnargliele a mano");
      } else {
        this.toastr.success("Il tecnico è stato invitato");
      }
      this.showModalInvitoTecnico = false;
      this.modelInvitoTecnico = new InvitoTecnico();
      this.getPageData();
      this.spinner.dismiss();
    }).catch((err) => {
      if(err == "not_tecnico") {
        this.toastr.error("L'utente da te invitato non è un tecnico");
      } else {
        this.toastr.error("C'è stato un errore con l'invito del tecnico");
      }
      this.spinner.dismiss();
    });
  }

  assegnaTecnico() {
    if(!confirm("Confermi di voler assegnare il tecnico?")) {
      return;
    }
    this.modelAssegnaTecnico.societaId = this.model.societa.id;
    this.modelAssegnaTecnico.assegnaPratichePrecedenti
    this.spinner.show();
    this.societaSrv.assegnaTecnico(this.modelAssegnaTecnico).then((res: string) => {
      if(res == "invite_sent") {
        this.toastr.success("Il tecnico ha ricevuto la mail di invito");
      } else if(res == "tecnico_added") {
        this.toastr.success("Il tecnico è stato associato"+ (this.auth.isAdmin ? " all'" : " alla tua ") +"agenzia");
      } else if(res == "tecnico_added_ok_pratiche_precedenti") {
        this.toastr.success("Il tecnico è stato associato"+ (this.auth.isAdmin ? " all'" : " alla tua ") +"agenzia e le pratiche precedenti gli sono state assegnate");
      } else if(res == "tecnico_added_error_pratiche_precedenti") {
        this.toastr.success("Il tecnico è stato associato"+ (this.auth.isAdmin ? " all'" : " alla tua ") +"agenzia ma c'è stato un errore nell'assegnazione delle pratiche precedenti. Dovrai assegnargliele a mano");
      } else {
        this.toastr.success("Il tecnico è stato invitato");
      }
      this.showModalInvitoTecnico = false;
      this.modelAssegnaTecnico = new AssegnaTecnico();
      this.getPageData();
      this.spinner.dismiss();
    }).catch((err) => {
      if(err == "not_tecnico") {
        this.toastr.error("L'utente da te invitato non è un tecnico");
      } else {
        this.toastr.error("C'è stato un errore con l'invito del tecnico");
      }
      this.spinner.dismiss();
    });
  }

  editUser(user: AnagraficaUtente) {
    if(!this.auth.isAdmin && !this.auth.isTeamLeader) {
      return;
    }
    this.router.navigate(["utente/" + user.id]);
  }

  nuovoUtente() {
    this.router.navigate(["utente/societa/" + this.model.societa.id, {new: "true"}]);
  }
}
