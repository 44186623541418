import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-conferma-mail',
  templateUrl: './conferma-mail.component.html',
  styleUrls: ['./conferma-mail.component.scss']
})
export class ConfermaMailComponent {

  message = "Caricando...";

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      var userId = params["token"];
      if(userId == null) {
        this.message = "Errore nella conferma della mail";
      } else {
        this.auth.confermaMail(userId).then(() => {
          this.toastr.success("Email confermata con successo, ora puoi fare il login");
          this.router.navigate(["/"]);
        }).catch(() => {
          this.message = "Errore nella conferma della mail";
        });
      }
    });
  }

}
