import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Pratica } from 'src/app/models/pratica';
import { PraticheService } from 'src/app/services/pratiche.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent {
  pratica: Pratica = new Pratica();
  token = "";
  documentiLabel = "";
  error = false;

  constructor(
    private route: ActivatedRoute,
    private praticheSrv: PraticheService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    var token = this.route.snapshot.paramMap.get('token') ?? "";
    if(token.length > 0) {
      this.token = token;
      this.praticheSrv.getPraticaDownload(token).then((res: any) => {
        this.pratica = res.pratica;
        this.documentiLabel = res.elencoDocumenti;
      }).catch((err: any) => {
        this.error = true;
      });
    }
  }

  getFile() {
    var url = environment.API_URL + "pratiche/download/file/" + this.token;
    window.open(url, "_blank");
  }
}
