import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  agenzie = {
    selectEsterne: false,
    scrollY: 0
  }

  constructor() { }


}
