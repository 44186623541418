import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public email: string = "";
  public password: string = "";
  public showPassword: boolean = false;
  public loading: boolean = false;
  public showError: boolean = false;
  public showModalForgot: boolean = false;
  public showModalRegistrazione: boolean = false;
  public showModalNonVerificato: boolean = false;
  public showModalConfermaMail: boolean = false;
  public emailRecupero: string = "";
  public mustLogin = false;
  public showModalTermini = false;
  public tempUserId = "";

  constructor(private auth: AuthService, private router: Router, private toastr: ToastrService, private spinner: SpinnerService) { }

  ngOnInit() {
    if(this.auth.token.trim().length > 0) {
      this.loading = true;
      this.auth.checkAuth().then(() => {
        this.loading = false;
        this.router.navigate(["stats"]);
      }).catch(() => {
        this.loading = false;
        this.mustLogin = true;
      });
    } else {
      this.mustLogin = true;
    }
  }

  login() {
    if(!this.loading) {
      if(this.email.trim().length == 0 || this.password.trim().length == 0) {
        this.toastr.error("Inserisci tutti i dati richiesti");
        return;
      }
      this.loading = true;
      this.auth.login(this.email, this.password).then(() => {
        this.loading = false;
        this.router.navigate(["stats"]);
      }).catch((error: any) => {
        this.loading = false;
        if(error.error == "user_not_enabled") {
          this.toastr.error("Il tuo utente è disabilitato");
        } else if(error.error == "user_not_accepted") {
          this.showModalNonVerificato = true;
        } else if(error.error == "societa_not_accepted") {
          this.showModalNonVerificato = true;
        } else if(error.error == "terms_not_accepted") {
          this.tempUserId = error.userId;
          this.showModalTermini = true;
        } else if(error.error == "email_not_confirmed") {
          this.showModalConfermaMail = true;
        } else {
          this.toastr.error("Credenziali errate");
        }
        this.showError = true;
      });
    }
  }

  passwordDimenticata() {
    this.showModalForgot = true;
    this.emailRecupero = this.email;
  }

  resetPassword() {
      this.spinner.show();
      this.auth.forgotPassword(this.emailRecupero).then(() => {
        this.showModalForgot = false;
        this.spinner.dismiss();
        this.toastr.success("Ti abbiamo inviato una mail con le istruzioni per resettare la tua password", "");
        this.emailRecupero = "";
      }).catch(() => {
        this.spinner.dismiss();
        this.toastr.error("C'è stato un errore con la richiesta di reset password", "");
      });
  }

  accettaCondizioni() {
    this.spinner.show();
    this.auth.accettaCondizioni(this.tempUserId).then((res) => {
      this.auth.login(this.email, this.password).then((res) => {
        this.spinner.dismiss();
        this.router.navigate(["stats"]);
      }).catch(() => {
        this.spinner.dismiss();
        this.toastr.success("Grazie, ora puoi fare il login");
      });
    }).catch(() => {
      this.spinner.dismiss();
      this.toastr.error("C'è stato un errore")
    });
  }

  inviaMailConferma() {
    this.spinner.show();
    this.auth.inviaMailConferma(this.email).then(() => {
      this.toastr.success("Ti abbiamo inviato un'altra mail di conferma");
      this.spinner.dismiss();
      this.showModalConfermaMail = false;
    }).catch(() => {
      this.toastr.error("C'è stato un errore");
      this.spinner.dismiss();
    });
  }
}
