import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {

  public nuovaPassword: string = "";
  public confermaPassword: string = "";
  public loading: boolean = false;
  private meta: string = "";

  constructor( private auth: AuthService, private activatedRoute: ActivatedRoute, private toastr: ToastrService, private router: Router){}

  ngOnInit() {
    var meta = this.activatedRoute.snapshot.queryParamMap.get('meta');
    if(meta != null) {
      this.meta = meta;
    }
  }

  reset() {
    if(!this.auth.validatePassword(this.nuovaPassword)) {
      this.toastr.error("La password deve essere lunga almeno 8 caratteri e contenere almeno una lettera minuscola, una minuscola e un numero");
      return;
    }
    if(this.nuovaPassword != this.confermaPassword) {
      this.toastr.error("Le password devono coincidere");
      return;
    }
    this.auth.resetPassword(this.meta, this.nuovaPassword).then(() => {
      this.router.navigate(["/"]);
      this.toastr.success("Password cambiata correttamente, ora puoi fare il login.");
    }).catch(() => {
      this.toastr.error("Errore nel reset della password");
    });
  }
}
