<app-lp-template size="lg">
    <div class="px-2 pb-2 pt-2 input-block">
        <h2 class="text-center mt-0">Registrazione agenzia</h2>
        <form clrForm ngNativeValidate clrLayout="vertical" (submit)="register()">
            <h3>Dati Agenzia</h3>

            <div class="clr-row">
                <div class="clr-col">
                    <clr-input-container>
                        <label class="clr-required-mark">Ragione sociale</label>
                        <input clrInput type="text" [(ngModel)]="model.societa.ragioneSociale" name="ragioneSociale" required/>
                    </clr-input-container>
                </div>
            </div>
            <div class="clr-row">
                <div class="clr-col">
                    <clr-input-container>
                        <label class="clr-required-mark">Indirizzo</label>
                        <input clrInput type="text" [(ngModel)]="model.societa.indirizzo" name="indirizzo" required/>
                    </clr-input-container>
                </div>
            </div>
            <div class="clr-row">
                <div class="clr-col-md">
                    <clr-input-container>
                        <label class="clr-required-mark">CAP</label>
                        <input clrInput type="text" [(ngModel)]="model.societa.cap" name="cap" required/>
                    </clr-input-container>
                </div>
                <div class="clr-col-md">
                    <clr-input-container>
                        <label class="clr-required-mark">Comune</label>
                        <input clrInput type="text" [(ngModel)]="model.societa.citta" name="citta" required/>
                    </clr-input-container>
                </div>
                <div class="clr-col-md">
                    <clr-input-container>
                        <label class="clr-required-mark">Provincia</label>
                        <input clrInput type="text" [(ngModel)]="model.societa.provincia" name="provincia" required/>
                    </clr-input-container>
                </div>
                <div class="clr-col-md">
                    <clr-input-container>
                        <label class="clr-required-mark">Regione</label>
                        <input clrInput type="text" [(ngModel)]="model.societa.regione" name="regione" required/>
                    </clr-input-container>
                </div>
            </div>
            
            <clr-input-container>
                <label class="clr-required-mark">Codice fiscale</label>
                <input clrInput type="text" [(ngModel)]="model.societa.cf" name="cf" required/>
            </clr-input-container>
            <clr-input-container>
                <label class="clr-required-mark">Partita IVA</label>
                <input clrInput type="text" [(ngModel)]="model.societa.piva" name="piva" required/>
            </clr-input-container>
            <clr-input-container>
                <label class="clr-required-mark">PEC</label>
                <input clrInput type="text" [(ngModel)]="model.societa.pec" name="pec" required/>
            </clr-input-container>
            <clr-input-container>
                <label class="clr-required-mark">Codice univoco</label>
                <input clrInput type="text" [(ngModel)]="model.societa.sdi" name="sdi" required/>
            </clr-input-container>

            <h3 class="mt-3">Dati titolare agenzia</h3>
            <clr-input-container>
                <label class="clr-required-mark">Nome e cognome</label>
                <input clrInput type="text" [(ngModel)]="model.utente.nome" name="nome" required/>
            </clr-input-container>
            <clr-input-container>
                <label class="clr-required-mark">Email</label>
                <input clrInput type="text" [(ngModel)]="model.utente.email" name="email" required/>
            </clr-input-container>
            <clr-input-container>
                <label class="clr-required-mark">Telefono</label>
                <input clrInput type="text" [(ngModel)]="model.utente.telefono" name="telefono" required/>
            </clr-input-container>
            <clr-password-container>
                <label class="clr-required-mark">Password</label>
                <input clrPassword placeholder="" [(ngModel)]="model.utente.password" name="password" required/>
            </clr-password-container>
            <clr-password-container>
                <label class="clr-required-mark">Conferma password</label>
                <input clrPassword placeholder="" [(ngModel)]="confermaPassword" name="confermaPassword" required/>
            </clr-password-container>
            <div class="clr-form-control">
                <div class="clr-control-container">
                    <div class="clr-checkbox-wrapper">
                        <input type="checkbox" clrCheckbox required id="condizioni" name="condizioni"/>
                        <label for="condizioni">Accetto le <a target="_blank" href="../../../assets/CondizioniUso.pdf">condizioni d'uso</a></label>
                    </div>
                </div>
            </div>
            <button class="btn btn-primary btn-block mt-2" type="submit">
                <app-loading *ngIf="loading"></app-loading>
                <span *ngIf="!loading">INVIA</span>
            </button>
        </form>
    </div>
</app-lp-template>