import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { faFileExcel, faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faPlus, faPrint, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { MotiviAnnullamentoLabel, MotiviAnnullamentoPratica, MotiviFermoLabel, StatiPratica } from 'src/app/const/pratica-const';
import { UserRoles } from 'src/app/const/user-roles';
import { AnagraficaSocieta } from 'src/app/models/anagrafica-societa';
import { AnagraficaUtente } from 'src/app/models/anagrafica-utente';
import { CercaPraticheModel } from 'src/app/models/cerca-pratiche-model';
import { Pratica } from 'src/app/models/pratica';
import { PraticheList } from 'src/app/models/pratiche-list';
import { AuthService } from 'src/app/services/auth.service';
import { PraticheService } from 'src/app/services/pratiche.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pratiche',
  templateUrl: './pratiche.component.html',
  styleUrls: ['./pratiche.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PraticheComponent {
  faPenToSquare = faPenToSquare;
  faTrashCan = faTrashCan;
  faTriangleExclamation = faTriangleExclamation;
  faFileExcel = faFileExcel;
  faPlus = faPlus;
  faPrint = faPrint;
  cercaModel: CercaPraticheModel = new CercaPraticheModel();
  pratiche: Pratica[] = [];
  tecnici: AnagraficaUtente[] = [];
  listaSocieta: AnagraficaSocieta[] = [];
  statiPratica = StatiPratica;
  annullaPraticaId = 0;
  annullaMotivo = MotiviAnnullamentoPratica.SCADUTO_INCARICO;
  showModalAnnullamento = false;
  MotiviAnnullamentoPratica = MotiviAnnullamentoPratica;
  MotiviAnnullamentoLabel = MotiviAnnullamentoLabel;
  showModalSelectAgenziaCrea = false;
  societaIdSelected = 0;
  pagine = 1;
  pagineArray: number[] = [];
  pageSize = 20;
  MotiviFermoLabel = MotiviFermoLabel;


  constructor(private praticheSrv: PraticheService, private toastr: ToastrService, private spinner: SpinnerService, private router: Router, public auth: AuthService, private route: ActivatedRoute) {}

  ngOnInit() {
    var agenziaId = this.route.snapshot.paramMap.get('agenziaId');
    if(this.auth.isAdmin && agenziaId != null) {
      this.cercaModel.agenziaId = parseInt(agenziaId);
    }
    this.cerca();
  }

  cerca() {
    this.spinner.show();
    this.praticheSrv.getPratiche(this.cercaModel).then((res: PraticheList) => {
      this.pratiche = res.list;
      if(this.tecnici.length == 0) {
        this.tecnici = res.tecnici;
      }
      if(this.listaSocieta.length == 0) {
        this.listaSocieta = res.societa;
      }
      this.pagine = res.pagine;
      for(let i=1; i <= this.pagine; i++) {
        this.pagineArray.push(i);
      }
      this.spinner.dismiss();
    }).catch((err: any) => {
      this.spinner.dismiss();
    });
  }

  setPage(pagina: number) {
    this.cercaModel.page = pagina;
    this.cerca();
  }

  edit(pratica: Pratica) { 
    this.router.navigate(['pratica-form/' + pratica.id]);
  }

  crea() {
    if(this.auth.isTeamLeader) {
      this.societaIdSelected = this.listaSocieta[0].id;
      this.showModalSelectAgenziaCrea = true;
    } else {
      this.router.navigate(['pratica-form']);
    }
  }

  creaDaTeamLeader() {
    this.router.navigate(['pratica-form', {societaId: this.societaIdSelected}]);
  }

  annulla(praticaId: number) {
    this.annullaPraticaId = praticaId;
    this.showModalAnnullamento = true;
  }

  annullaConfirm() {
    if(!confirm("Confermi di voler annullare questa pratica?")) {
      return;
    }
    this.spinner.show();
    this.praticheSrv.annullaPratica(this.annullaPraticaId, this.annullaMotivo).then(() => {
      this.toastr.success("Pratica annullata");
      this.spinner.dismiss();
      this.showModalAnnullamento = false;
      this.cerca();
    }).catch((error: string) => {
      this.toastr.error("Errore nell'annullamento della pratica");
      this.spinner.dismiss();
      this.showModalAnnullamento = false;
    });
  }

  elimina(praticaId: number) {
    if(!confirm("Confermi di voler eliminare definitivamente questa pratica?")) {
      return;
    }
    if(!confirm("Insieme alla pratica saranno eliminati anche tutti i dati e i documenti. Questa azione non è reversibile. Confermi di voler eliminare la pratica?")) {
      return;
    }
    this.spinner.show();
    this.praticheSrv.eliminaPratica(praticaId).then(() => {
      this.toastr.success("Pratica eliminata");
      this.spinner.dismiss();
      this.cerca();
    }).catch((error: string) => {
      this.toastr.error("Errore nell'eliminazione della pratica");
      this.spinner.dismiss();
    });
  }

  export(format: string) {
    if(this.pratiche.length == 0) {
      return;
    }
    var form = document.createElement("form");
    var token = document.createElement("input"); 
    var userid = document.createElement("input");  
    var body = document.createElement("input");  

    form.method = "POST";
    if(format == "pdf") {
      form.action = environment.API_URL + "pratiche/pdf";   
    } else if(format == "xls") {
      form.action = environment.API_URL + "pratiche/export";   
    } else {
      return;
    }

    token.value = this.auth.token;
    token.name = "token";
    form.appendChild(token);  

    userid.value = this.auth.user.id;
    userid.name = "userid";
    form.appendChild(userid);

    body.value = JSON.stringify(this.cercaModel);
    body.name = "body";
    form.appendChild(body); 

    form.style.display = "none";
    form.setAttribute("target", "_blank");
    form.setAttribute("enctype", "multipart/form-data");
    document.body.appendChild(form);
    form.submit();
  }

}
