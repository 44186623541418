import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faEye, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { AnagraficaSocieta } from 'src/app/models/anagrafica-societa';
import { CercaSocietaModel } from 'src/app/models/cerca-societa-model';
import { SocietaService } from 'src/app/services/societa.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-agenzie',
  templateUrl: './agenzie.component.html',
  styleUrls: ['./agenzie.component.scss']
})
export class AgenzieComponent {
  agenzieInterne: AnagraficaSocieta[] = [];
  agenzieEsterne: AnagraficaSocieta[] = [];
  showInterne = true;
  showEsterne = false;
  cercaModel: CercaSocietaModel = new CercaSocietaModel();
  faTriangleExclamation = faTriangleExclamation;
  faPenToSquare = faPenToSquare;
  faTrashCan = faTrashCan;
  faEye = faEye;

  constructor(
    private societaSrv: SocietaService,
    private spinner: SpinnerService,
    private router: Router,
    private state: StateService
  ) {}

  ngOnInit() {
    if(this.state.agenzie.selectEsterne) {
      this.switchEsterne();
    }
    this.cerca();
  }

  cerca() {
    this.spinner.show();
    this.societaSrv.getAllSocieta(this.cercaModel).then((agenzie) => {
      this.agenzieInterne = agenzie.interne;
      this.agenzieEsterne = agenzie.esterne;
      this.spinner.dismiss();
      setTimeout(() => {
        window.scrollTo(0, this.state.agenzie.scrollY);
      }, 250);
    }).catch(() => {
      this.spinner.dismiss();
    });
  }

  edit(agenzia: AnagraficaSocieta) {
    this.state.agenzie.selectEsterne = this.showEsterne;
    this.state.agenzie.scrollY = window.scrollY;
    this.router.navigate(["anagrafica-societa/" + agenzia.id]);
  }

  switchInterne() {
    this.showInterne = true;
    this.showEsterne = false;
  }

  switchEsterne() {
    this.showInterne = false;
    this.showEsterne = true;
  }

  visualizzaPraticheAgenzia(agenziaId: number) {
    this.state.agenzie.selectEsterne = this.showEsterne;
    this.state.agenzie.scrollY = window.scrollY;
    this.router.navigate(["pratiche", {agenziaId: agenziaId}]);
  }

}
