import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { AnagraficaUtente } from 'src/app/models/anagrafica-utente';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UtentiService } from 'src/app/services/utenti.service';

@Component({
  selector: 'app-utenti',
  templateUrl: './utenti.component.html',
  styleUrls: ['./utenti.component.scss']
})
export class UtentiComponent {
  faTrashCan = faTrashCan;
  faPenToSquare = faPenToSquare;
  faXmark = faXmark;
  faCheck = faCheck;
  utenti: AnagraficaUtente[] = [];
  disabilitati: AnagraficaUtente[] = [];

  constructor(private spinner: SpinnerService, private utentiSrv: UtentiService, private toastr: ToastrService, private router: Router) {}

  ngOnInit() {
    this.spinner.show();
    this.utentiSrv.getUtenti().then((res) => {
      this.utenti = res;
      this.disabilitati = this.utenti.filter((user) => { return !user.active});
      this.utenti = this.utenti.filter((user) => { return user.active});
      this.spinner.dismiss();
    }).catch((err) => {
      this.toastr.error("Errore nel reperire la lista degli utenti");
      this.spinner.dismiss();
    });
  }

  crea() {
    this.router.navigate(["utente", {new: "true"}]);
  }

  edit(user: AnagraficaUtente) {
    this.router.navigate(["utente/" + user.id]);
  }
}
