<div class="page-container container-large">
    <h3 class="section-title">Agenzie</h3>
    <form>
        <div class="cerca pt-2 pr-2 pb-0 pl-2">
            <div class="clr-row input-block">
                <div class="clr-col-md">
                    <clr-input-container>
                        <label>Provincia</label>
                        <input clrInput type="text" [(ngModel)]="cercaModel.provincia" name="provincia"/>
                    </clr-input-container>
                </div>
                <div class="clr-col-md">
                    <clr-input-container>
                        <label>Località</label>
                        <input clrInput type="text" [(ngModel)]="cercaModel.localita" name="localita"/>
                    </clr-input-container>
                </div>
                <div class="clr-col-md">
                    <clr-input-container>
                        <label>Ragione Sociale</label>
                        <input clrInput type="text" [(ngModel)]="cercaModel.ragioneSociale" name="ragioneSociale"/>
                    </clr-input-container>
                </div>
            </div>
            <div class="text-right mt-1">
                <button class="btn btn-primary" type="submit" (click)="cerca()">Cerca</button>
            </div>
        </div>
    </form>
</div>

<div class="page-container container-large pl-1 pr-1 mt-0 pt-0">
    <div class="custom-tabs-switch">
        <p (click)="switchInterne()" [ngClass]="{'active': showInterne}">Interne</p>
        <p (click)="switchEsterne()" [ngClass]="{'active': showEsterne}">Esterne</p>
    </div>
    <p><fa-icon class="yellow" [icon]="faTriangleExclamation"></fa-icon> = Da approvare</p>
    <div class="table-container" *ngIf="showInterne">
        <table class="table pratiche-table">
            <thead>
                <th></th>
                <th></th>
                <th>Ragione Sociale</th>
                <th>Città</th>
                <th>Provincia</th>
                <th>Telefono</th>
                <th>Email</th>
                <th></th>
            </thead>
            <tbody>
                <tr *ngFor="let agenzia of agenzieInterne; index as i">
                    <td><fa-icon class="yellow" [icon]="faTriangleExclamation" *ngIf="!agenzia.accettatoDaStudio"></fa-icon></td>
                    <td><img src="{{agenzia.logo}}" /></td>
                    <td>{{agenzia.ragioneSociale}}</td>
                    <td>{{agenzia.citta}}</td>
                    <td>{{agenzia.provincia}}</td>
                    <td>{{agenzia.telefono}}</td>
                    <td>{{agenzia.email}}</td>
                    <td>
                        <div class="btn-group btn-primary">
                            <button class="btn btn-primary btn-icon" (click)="edit(agenzia)"><fa-icon [icon]="faPenToSquare"></fa-icon></button>
                            <button class="btn btn-primary btn-icon" (click)="visualizzaPraticheAgenzia(agenzia.id)"><fa-icon [icon]="faEye"></fa-icon></button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="table-container" *ngIf="showEsterne">
        <table class="table pratiche-table">
            <thead>
                <th></th>
                <th></th>
                <th>Ragione Sociale</th>
                <th>Città</th>
                <th>Provincia</th>
                <th>Telefono</th>
                <th>Email</th>
                <th></th>
            </thead>
            <tbody>
                <tr *ngFor="let agenzia of agenzieEsterne; index as i">
                    <td><fa-icon class="yellow" [icon]="faTriangleExclamation" *ngIf="!agenzia.accettatoDaStudio"></fa-icon></td>
                    <td><img src="{{agenzia.logo}}" /></td>
                    <td>{{agenzia.ragioneSociale}}</td>
                    <td>{{agenzia.citta}}</td>
                    <td>{{agenzia.provincia}}</td>
                    <td>{{agenzia.telefono}}</td>
                    <td>{{agenzia.email}}</td>
                    <td>
                        <div class="btn-group btn-primary">
                            <button class="btn btn-primary btn-icon" (click)="edit(agenzia)"><fa-icon [icon]="faPenToSquare"></fa-icon></button>
                            <button class="btn btn-primary btn-icon" (click)="visualizzaPraticheAgenzia(agenzia.id)"><fa-icon [icon]="faEye"></fa-icon></button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>