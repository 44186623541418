<div class="page-stats">
    <div class="clr-row">
        <div class="clr-col-lg-6">
            <div class="stat-box congratulazioni">
                <div class="award">
                    <fa-icon [icon]="faAward"></fa-icon>
                </div>
                <h3>Congratulazioni {{auth.user.nome}}</h3>
                <p>Nel {{anno}} hai gestito <strong>{{stats.numPraticheAnno}}</strong> pratiche</p>
            </div>
        </div>
        <div class="clr-col-lg-3 clr-col-sm-6">
            <div class="stat-box">
                <div class="stat-icon green">
                    <div class="icon-circle">
                        <fa-icon [icon]="faFile"></fa-icon>
                    </div>
                    <h2>{{stats.numPraticheTot}} pratiche</h2>
                    <p>Gestite da quando sei iscritto al portale</p>
                </div>
            </div>
        </div>
        <div class="clr-col-lg-3 clr-col-sm-6">
            <div class="stat-box">
                <div class="stat-icon red">
                    <div class="icon-circle">
                        <fa-icon [icon]="faBox"></fa-icon>
                    </div>
                    <h2>{{stats.numPraticheDaGestire}} pratiche</h2>
                    <p>ancora da gestire</p>
                </div>
            </div>
        </div>
        <div class="clr-col-lg-6">
            <div class="stat-box">
                <div class="stat-header">
                    <h4>Pratiche ferme</h4>
                    <select clrSelect [(ngModel)]="annoPraticheFerme" (change)="reloadCharts()">
                        <ng-container *ngFor="let anno of anniPraticheFerme">
                            <option [value]="anno">{{anno}}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="chart">
                    <apx-chart
                        [series]="chartFerme.series"
                        [chart]="chartFerme.chart"
                        [labels]="chartFerme.labels"
                        [legend]="chartFerme.legend"
                    ></apx-chart>
                </div>
            </div>
        </div>
        <div class="clr-col-lg-6">
            <div class="stat-box">
                <div class="stat-header">
                    <h4>Situazione pratiche</h4>
                    <select clrSelect [(ngModel)]="annoPraticheStati" (change)="reloadCharts()">
                        <ng-container *ngFor="let anno of anniPraticheStati">
                            <option [value]="anno">{{anno}}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="chart">
                    <apx-chart
                        [series]="chartStati.series"
                        [chart]="chartStati.chart"
                        [labels]="chartStati.labels"
                        [legend]="chartStati.legend"
                    ></apx-chart>
                </div>
            </div>
        </div>
        <div class="clr-col-lg-6">
            <div class="stat-box">
                <div class="stat-header">
                    <h4>Pratiche ferme per agenzia</h4>
                </div>
                <table class="table">
                    <thead>
                            <th>Agenzia</th>
                            <th>N. pratiche ferme</th>
                            <th>Motivo fermo pratica</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of stats.fermeAgenzie">
                            <td>{{row.agenzia}}</td>
                            <td>{{row.numero}}</td>
                            <td>{{row.motivo}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="clr-col-lg-6">
            <div class="stat-box">
                <div class="stat-header">
                    <h4>Pratiche per agenzia</h4>
                </div>
                <table class="table">
                    <thead>
                            <th>Agenzia</th>
                            <th>Nuove</th>
                            <th>In lavorazione</th>
                            <th>Concluse</th>
                            <th>Rogitate</th>
                            <th>Ferme</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of stats.statiAgenzie">
                            <td>{{row.agenzia}}</td>
                            <td>{{row.nuove}}</td>
                            <td>{{row.inLavorazione}}</td>
                            <td>{{row.concluse}}</td>
                            <td>{{row.rogitate}}</td>
                            <td>{{row.ferme}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>