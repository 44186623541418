import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AnagraficaSocieta } from '../models/anagrafica-societa';
import { AnagraficaSocietaCompleta } from '../models/anagrafica-societa-completa';
import { InvitoTecnico } from '../models/invito-tecnico';
import { StringResultApi } from '../models/string-result-api';
import { CercaSocietaModel } from '../models/cerca-societa-model';
import { AssegnaTecnico } from '../models/assegna-tecnico';
import { UtenteSocieta } from '../models/utente-societa';

@Injectable({
  providedIn: 'root'
})
export class SocietaService {

  constructor(private http: HttpClient) { }

  getByIdAdmin(id: number) {
    return new Promise<AnagraficaSocietaCompleta>((resolve, reject) => {
      this.http.get(environment.API_URL + "societa/id/" + id).subscribe({ next: (res) => {
        var societa = res as AnagraficaSocietaCompleta;
        resolve(societa);
      }, error: (err: HttpErrorResponse) => {
        reject(err.error);
      }});
    });
  }

  getCompletaById(id: number) {
    return new Promise<AnagraficaSocietaCompleta>((resolve, reject) => {
      this.http.get(environment.API_URL + "societa/completa/" + id).subscribe({ next: (res) => {
        var societa = res as AnagraficaSocietaCompleta;
        resolve(societa);
      }, error: (err: HttpErrorResponse) => {
        reject(err.error);
      }});
    });
  }

  getByUser() {
    return new Promise<AnagraficaSocietaCompleta>((resolve, reject) => {
      this.http.get(environment.API_URL + "societa/user").subscribe({ next: (res) => {
        var societa = res as AnagraficaSocietaCompleta;
        resolve(societa);
      }, error: (err: HttpErrorResponse) => {
        reject(err.error);
      }});
    });
  }

  edit(soc: AnagraficaSocieta) {
    return new Promise<any>((resolve, reject) => {
      this.http.put(environment.API_URL + "societa", soc).subscribe({ next: (res) => {
        resolve(null);
      }, error: (err: HttpErrorResponse) => {
        reject(err.error);
      }});
    });
  }

  invitaTecnico(invito: InvitoTecnico) {
    return new Promise<string>((resolve, reject) => {
      this.http.post(environment.API_URL + "societa/tecnico", invito).subscribe({next: (res) => {
        resolve((res as StringResultApi).result);
      }, error: (err) => {
        reject((err as HttpErrorResponse).error);
      }});
    });
  }

  assegnaTecnico(model: AssegnaTecnico) {
    return new Promise<string>((resolve, reject) => {
      this.http.post(environment.API_URL + "societa/admin/tecnico", model).subscribe({next: (res) => {
        resolve((res as StringResultApi).result);
      }, error: (err) => {
        reject((err as HttpErrorResponse).error);
      }});
    });
  }

  rimuoviTecnico(idTecnico: string, tecnicoRiassegnazione: string, societaId: number) {
    return new Promise<string>((resolve, reject) => {
      var url = environment.API_URL + "societa/tecnico/" + idTecnico;
      if(tecnicoRiassegnazione.trim().length > 0) {
        url += "?tecnicoRiassegnazionePratiche=" + tecnicoRiassegnazione;
      }
      if(societaId != 0) {
        url += (tecnicoRiassegnazione.trim().length > 0 ? "&" : "?") + "societaId=" + societaId;
      }
      this.http.delete(url).subscribe({next: (res) => {
        resolve((res as StringResultApi).result);
      }, error: (err) => {
        reject((err as HttpErrorResponse).error);
      }});
    });
  }

  getAllSocieta(model: CercaSocietaModel) {
    return new Promise<any>((resolve, reject) => {
      var query = Object.entries(model).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&');
      this.http.get(environment.API_URL + "societa/all?" + query).subscribe({next: (lista) => {
        resolve(lista);
      }, error: (err) => {
        reject();
      }});
    });
  }

  aggiungiUtenteAdAgenzia(agenziaId: number, userId: string) : Promise<any> {
    return new Promise<any>((resolve, reject) => {
      var model = new UtenteSocieta();
      model.societaId = agenziaId;
      model.utenteId = userId;
      this.http.put(environment.API_URL + "societa/user/add", model).subscribe({next: (res: any) => {
        resolve(res);
      }, error: (err: HttpErrorResponse) => {
        reject(err.error);
      }})
    });
  }

  rimuoviUtenteDaAgenzia(agenziaId: number, userId: string) : Promise<any> {
    return new Promise<any>((resolve, reject) => {
      var model = new UtenteSocieta();
      model.societaId = agenziaId;
      model.utenteId = userId;
      this.http.put(environment.API_URL + "societa/user/remove", model).subscribe({next: (res: any) => {
        resolve(res);
      }, error: (err: HttpErrorResponse) => {
        reject(err.error);
      }})
    });
  }
  
}
