<div class="page-container p-2">
    <h2 class="text-center">Team Leaders</h2>
    <div class="text-center"><button class="btn btn-primary" (click)="crea()">+ Nuovo team leader</button></div>
    <div class="table-container">
        <table class="table">
            <thead>
                <th>Nome e cognome</th>
                <th>Email</th>
                <th></th>
                <th></th>
            </thead>
            <tbody>
                <ng-container *ngFor="let user of leaders; index as i">
                    <tr *ngIf="user.active">
                        <td>{{user.nome}}</td>
                        <td>{{user.email}}</td>
                        <td>
                            <div class="btn-group btn-primary">
                                <button class="btn btn-primary btn-icon" (click)="edit(user)"><fa-icon [icon]="faPenToSquare"></fa-icon></button>
                                <button class="btn btn-danger btn-icon"><fa-icon [icon]="faTrashCan"></fa-icon></button>
                            </div>
                        </td>
                        <td><button (click)="openModalSocieta(user)" class="btn btn-primary">Agenzie</button></td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <h2 class="text-center">Disabilitati</h2>
    <div class="table-container">
        <table class="table">
            <thead>
                <th>Nome e cognome</th>
                <th>Email</th>
                <th></th>
                <th></th>
            </thead>
            <tbody>
                <ng-container *ngFor="let user of leaders; index as i">
                    <tr *ngIf="!user.active">
                        <td>{{user.nome}}</td>
                        <td>{{user.email}}</td>
                        <td>
                            <div class="btn-group btn-primary">
                                <button class="btn btn-primary btn-icon" (click)="edit(user)"><fa-icon [icon]="faPenToSquare"></fa-icon></button>
                                <button class="btn btn-danger btn-icon"><fa-icon [icon]="faTrashCan"></fa-icon></button>
                            </div>
                        </td>
                        <td><button (click)="openModalSocieta(user)" class="btn btn-primary">Agenzie</button></td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>

<clr-modal [(clrModalOpen)]="showModalSocieta">
    <h3 class="modal-title text-center">Agenzie assegnate al team leader</h3>
    <div class="modal-body">
        <h4 *ngIf="selectedLeader.societa.length == 0" class="text-center">Il team leader non ha ancora nessuna agenzia associata</h4>
        <div class="list-agenzie">
            <div class="clr-row" *ngFor="let soc of selectedLeader.societa">
                <div class="clr-col">{{soc.ragioneSociale}}</div>
                <div class="clr-col-4"><button class="btn btn-danger" (click)="rimuoviAgenzia(soc)">Rimuovi</button></div>
            </div>
        </div>
        <hr/>
        <h4 class="text-center"><strong>Assegna agenzia</strong></h4>
        <div class="clr-row">
            <div class="clr-col-12">
                <clr-select-container class="select-agenzia-container">
                    <select clrSelect [(ngModel)]="indexAgenzia" name="agenzia">
                        <option *ngFor="let soc of agenzieFiltered; index as i" [value]="i">{{agenzieFiltered[i].ragioneSociale}}</option>
                    </select>
                </clr-select-container>
            </div>
            <div class="clr-col-12 text-center"><button class="btn btn-primary" (click)="aggiungiAgenzia()">Assegna</button></div>
        </div>
    </div>
</clr-modal>
