import { Component } from '@angular/core';
import { SocietaService } from 'src/app/services/societa.service';

@Component({
  selector: 'app-assistenza',
  templateUrl: './assistenza.component.html',
  styleUrls: ['./assistenza.component.scss']
})
export class AssistenzaComponent {

  interna = false;

  constructor(private societaSrv: SocietaService) {}

  ngOnInit() {
    this.societaSrv.getByUser().then((res) => {
      this.interna = res.societa.interna;
    }).catch(() => {});
  }

}
