import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserRoles } from '../const/user-roles';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private auth: AuthService, 
    private _router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if(this.auth.token.length == 0) {
      this._router.navigate(['']);
      return false;
    } else {
      if(next.data["roles"] != null) {
        if (next.data["roles"].indexOf(this.auth.user.ruolo) != -1) {
          return true;
        } else {
          this._router.navigate(['']);
          return false;
        }
      } else {
        return true;
      }
    }
  }
}
