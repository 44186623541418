<app-lp-template>
    <div class="px-3 pb-3 pt-2 input-block">
        <h2 class="text-center mt-0">Accedi</h2>
        <form (submit)="login()">
            <clr-input-container>
                <label>Email</label>
                <input clrInput placeholder="" [(ngModel)]="email" type="email" name="email" tabindex="0" />
            </clr-input-container>
            <clr-password-container>
                <label>Password</label>
                <input clrPassword placeholder="" [(ngModel)]="password" name="password" tabindex="0" />
            </clr-password-container>
            <a href="#" (click)="passwordDimenticata()" class="mt-1 a-hover d-block text-right">Hai dimenticato la password?</a>
            <button class="btn btn-primary btn-block" type="submit">
                <app-loading *ngIf="loading"></app-loading>
                <span *ngIf="!loading">ACCEDI</span>
            </button>
        </form>
        <p class="text-center">Non hai un account?</p>
        <button class="btn btn-block" (click)="showModalRegistrazione = true">Registrati</button>
    </div>
</app-lp-template>

<clr-modal [(clrModalOpen)]="showModalForgot">
    <h3 class="modal-title">Hai dimenticato la password?</h3>
    <div class="modal-body">
        <p>Inserisci qui il tuo indirizzo mail per ricevere un codice di ripristino</p>
        <div class="input-block">
            <clr-input-container>
                <label>Email</label>
                <input clrInput placeholder="" [(ngModel)]="emailRecupero" type="email" />
            </clr-input-container>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="showModalForgot = false">Annulla</button>
        <button type="button" class="btn btn-primary" (click)="resetPassword()">
            <app-loading *ngIf="loading"></app-loading>
            <span *ngIf="!loading">Invia</span>
        </button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showModalRegistrazione">
    <h3 class="modal-title text-center">Sei un tecnico o un'agenzia?</h3>
    <div class="modal-body">
        <div class="clr-row">
            <div class="clr-col">
                <a routerLink="/registrazione-tecnico"  class="btn btn-block">Tecnico</a>
            </div>
            <div class="clr-col">
                <a routerLink="/registrazione-agenzia"  class="btn btn-block">Agenzia</a>
            </div>
        </div>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showModalTermini">
    <h3 class="modal-title text-center">Devi accettare i termini e condizioni per poter usare PraticaOk</h3>
    <div class="modal-body">
        <h4 class="text-center mb-3"><a target="_blank" href="../../../assets/CondizioniUso.pdf">Visualizza termini e condizioni</a></h4>
        <div class="clr-row">
            <div class="clr-col">
                <button class="btn btn-block btn-primary" (click)="accettaCondizioni()">Accetto</button>
            </div>
            <div class="clr-col">
                <button class="btn btn-block" (click)="showModalTermini = false;">Non accetto</button>
            </div>
        </div>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showModalNonVerificato">
    <h3 class="modal-title text-center">Account non ancora accettato</h3>
    <div class="modal-body">
        <p>Il tuo account deve prima essere accettato da PraticaOk.</p>
        <p>
            Per assistenza sull'attivazione del tuo account contatta il numero<br/>
            <a href="tel:+393516027476">+39 351 6027476</a><br/>
            (dal lunedí al venerdí 9.00-12.00 / 14.30-18.00)<br/>
            oppure puoi inviarci una mail a: <br/>
            <a href="mailto:info@praticaok.com">info@praticaok.com</a>
        </p>
        <div class="text-right mt-2"><button class="btn" (click)="showModalNonVerificato = false;">Chiudi</button></div>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showModalConfermaMail">
    <h3 class="modal-title text-center">Mail non confermata</h3>
    <div class="modal-body">
        <p>Non hai ancora confermato il tuo indirizzo mail.<br/>Ti abbiamo inviato una mail di conferma al termine della registrazione, controlla nello spam se non la vedi.</p>
        <p>Vuoi ricevere un'altra mail di conferma? Puoi farlo cliccando il bottone qui sotto</p>
        <div class="text-center"><button class="btn btn-primary" (click)="inviaMailConferma()">Invia mail di conferma</button></div>
    </div>
</clr-modal>