import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { UserRoles } from 'src/app/const/user-roles';
import { AnagraficaUtente } from 'src/app/models/anagrafica-utente';
import { AuthService } from 'src/app/services/auth.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UtentiService } from 'src/app/services/utenti.service';
import { Utils } from 'src/app/utils';

@Component({
  selector: 'app-utente',
  templateUrl: './utente.component.html',
  styleUrls: ['./utente.component.scss']
})
export class UtenteComponent {
  user: AnagraficaUtente = new AnagraficaUtente();
  edit = false;
  confermaPassword = "";
  disabilitatoStart = false;
  faCircleInfo = faCircleInfo;
  leader = false;

  constructor(private spinner: SpinnerService, private utentiSrv: UtentiService, private toastr: ToastrService, private route: ActivatedRoute, public auth: AuthService, private router: Router) {}

  ngOnInit() {
    var id = this.route.snapshot.paramMap.get('id');
    var societaId = this.route.snapshot.paramMap.get('societaId');
    var create = this.route.snapshot.paramMap.get('new');
    this.leader = this.route.snapshot.paramMap.get('leader') == "true";
    if(id != null) {
      this.edit = true;
      this.spinner.show();
      this.utentiSrv.getUtenteById(id).then((res) => {
        this.user = res;
        this.disabilitatoStart = !res.active;
        this.spinner.dismiss();
      }).catch((err) => {
        this.spinner.dismiss();
        this.toastr.error("Errore");
      });
    } else {
      if(create == "true") {
        this.edit = false;
        if(societaId != null && (this.auth.isAdmin || this.auth.isTeamLeader)) {
          this.user.societaId = parseInt(societaId);
        }
        this.user.ruolo = "Consulente";
      } else {
        this.edit = true;
        this.user = this.auth.user;
      }
    }
  }

  save() {
    this.user.telefono = this.user.telefono ?? "";
    if(!this.validate()) {
      return;
    }
    if(this.edit) {
      if(!this.disabilitatoStart && !this.user.active) {
        if(!confirm("Sei sicuro di voler disabilitare per questo utente l'accesso a Pratica Ok?")) {
          return;
        }
      }
      this.utentiSrv.editUtente(this.user).then((res) => {
        this.toastr.success("Utente salvato correttmanete");
        this.user = res;
        this.confermaPassword = "";
        this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
          this.router.navigate(['utente/' + this.user.id]);
        });
      }).catch((err) => {
        var errore = "Errore sconosciuto";
        switch(err) {
          case "error.user.already_exists":
            errore = "Esiste già un utente con questa mail";
            break;
          case "error.user.password_invalid":
            errore = "La password non è valida";
            break;
          case "error.user.email_invalid":
            errore = "Inserisci un indirizzo email valido";
            break;
          case "save_error":
            errore = "C'è stato un problema con il salvataggio, contattaci a info@praticaok.com";
            break;
        }
        this.toastr.error(errore);
      });
    } else {
      if(this.leader) {
        this.user.ruolo = UserRoles.TeamLeader;
        this.utentiSrv.createTeamLeader(this.user).then((res) => {
          this.toastr.success("Utente creato correttamente");
          this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
            this.router.navigate(['utente/' + res.id]);
          });
        }).catch((err) => {
          var errore = "Errore sconosciuto";
          switch(err) {
            case "error.user.already_exists":
              errore = "Esiste già un utente con questa mail";
              break;
            case "error.user.password_invalid":
              errore = "La password non è valida";
              break;
            case "error.user.email_invalid":
              errore = "Inserisci un indirizzo email valido";
              break;
            case "save_error":
              errore = "C'è stato un problema con il salvataggio, contattaci a info@praticaok.com";
              break;
          }
          this.toastr.error(errore);
        })
      } else {
        this.utentiSrv.createUtente(this.user).then((res) => {
          this.toastr.success("Utente creato correttamente");
          this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
            this.router.navigate(['utente/' + res.id]);
          });
        }).catch((err) => {
          var errore = "Errore sconosciuto";
          switch(err) {
            case "error.user.already_exists":
              errore = "Esiste già un utente con questa mail";
              break;
            case "error.user.password_invalid":
              errore = "La password non è valida";
              break;
            case "error.user.email_invalid":
              errore = "Inserisci un indirizzo email valido";
              break;
            case "save_error":
              errore = "C'è stato un problema con il salvataggio, contattaci a info@praticaok.com";
              break;
          }
          this.toastr.error(errore);
        })
      }
    }
  }

  validate() : boolean {
    if(!this.edit || (this.edit && this.user.password.length > 0)) {
      if(!this.auth.validatePassword(this.user.password)) {
        this.toastr.error("La password deve essere lunga almeno 8 caratteri e contenere almeno una lettera minuscola, una minuscola e un numero");
        return false;
      }
    }
    if(this.user.password != this.confermaPassword) {
      this.toastr.error("Le due password devono coincidere");
      return false;
    }
    return true;
  }
}
