<div class="page-container p-2 input-block">
    <h2 class="text-center">{{ edit ? 'Modifica' : 'Nuovo' }} utente</h2>
    <form clrForm ngNativeValidate clrLayout="vertical" (submit)="save()">
        <div class="clr-row">
            <div class="clr-col-12">
                <clr-input-container>
                    <label class="clr-required-mark">Nome e cognome</label>
                    <input clrInput type="text" [(ngModel)]="user.nome" name="nome" required/>
                </clr-input-container>
            </div>
            <div class="clr-col-12">
                <clr-input-container>
                    <label class="clr-required-mark">Email</label>
                    <input clrInput type="email" [(ngModel)]="user.email" name="emailUtente" required/>
                </clr-input-container>
            </div>
            <div class="clr-col-12">
                <clr-input-container>
                    <label>Telefono</label>
                    <input clrInput type="text" [(ngModel)]="user.telefono" name="telefono"/>
                </clr-input-container>
            </div>
            <div class="clr-col-12" *ngIf="!leader && !auth.isConsulente">
                <clr-select-container>
                    <label>Ruolo</label>
                    <select clrSelect [(ngModel)]="user.ruolo" name="ruolo">
                        <option value="Consulente">Consulente</option>
                        <option value="Titolare Agenzia">Titolare agenzia</option>
                        <option value="Responsabile Agenzia">Responsabile agenzia</option>
                        <option value="Team Leader" *ngIf="auth.isAdmin">Team Leader</option>
                    </select>
                </clr-select-container>
            </div>
            <div class="clr-col-12">
                <clr-input-container>
                    <label>Inviare mail in copia conoscenza
                        <a href="javascript://" role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg tooltip-top-left">
                            <fa-icon [icon]="faCircleInfo"></fa-icon>
                            <span class="tooltip-content">Le mail indirizzate a questo utente, verranno inviate in copia conoscenza agli indirizzi inseriti nel campo qui sotto. Puoi inserire più indirizzi email separati da una virgola</span>
                        </a>
                    </label>
                    <input clrInput type="email" [(ngModel)]="user.emailCC" name="emailCC"/>
                </clr-input-container>
            </div>
            <div class="clr-col-12">
                <clr-password-container>
                    <label [ngClass]="{'clr-required-mark': !edit}">{{ !edit ? 'Password' : 'Nuova password (lascia vuoto per non cambiarla)'}}</label>
                    <input clrPassword placeholder="" [(ngModel)]="user.password" name="passwordNew" required *ngIf="!edit"/>
                    <input clrPassword placeholder="" [(ngModel)]="user.password" name="passwordNew" *ngIf="edit"/>
                </clr-password-container>
            </div>
            <div class="clr-col-12">
                <clr-password-container>
                    <label [ngClass]="{'clr-required-mark': !edit}">Conferma password</label>
                    <input clrPassword placeholder="" [(ngModel)]="confermaPassword" name="confermaPassword" required *ngIf="!edit"/>
                    <input clrPassword placeholder="" [(ngModel)]="confermaPassword" name="confermaPassword" *ngIf="edit"/>
                </clr-password-container>
            </div>
            <div class="clr-col-12" *ngIf="edit">
                <div class="clr-form-control">
                    <div class="clr-control-container">
                        <div class="clr-checkbox-wrapper">
                            <input type="checkbox" clrCheckbox id="inactive" name="inactive" [ngModel]="!user.active" (ngModelChange)="user.active = !$event"/>
                            <label for="inactive">Disabilitato</label>
                        </div>
                    </div>
                </div> 
            </div>
            <div class="clr-col-12" *ngIf="auth.isAdmin">
                <div class="clr-form-control">
                    <div class="clr-control-container">
                        <div class="clr-checkbox-wrapper">
                            <input type="checkbox" clrCheckbox id="interno" name="interno" [ngModel]="user.interno" (ngModelChange)="user.interno = $event"/>
                            <label for="interno">Utente interno (valido solo per società ibride con tecnici sia interni che esterni)</label>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
        <ng-container *ngIf="auth.isAdmin">
            <h3 class="mt-3">Altri dati</h3>

            <div class="clr-form-control">
                <div class="clr-control-container">
                    <div class="clr-checkbox-wrapper">
                        <input type="checkbox" clrCheckbox id="accettatoDaStudio" name="accettatoDaStudio" [(ngModel)]="user.accettatoDaStudio"/>
                        <label for="accettatoDaStudio">Utente confermato da studio</label>
                    </div>
                </div>
            </div>

            <p><strong>Data accettazione condizioni</strong>: {{ user.dataAccettazioneCondizioni != "01/01/0001" ? user.dataAccettazioneCondizioni : "Non ancora accettate" }}</p>

            <clr-textarea-container>
                <label>Nota amministratore</label>
                <textarea clrTextarea [(ngModel)]="user.notaAmministratore" name="notaAmministratore"></textarea>
            </clr-textarea-container>
        </ng-container>
        <div class="clr-col-12 text-center mt-2">
            <button type="submit" class="btn btn-primary">Salva</button>
        </div>
    </form>
</div>