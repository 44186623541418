import { TipiDocumento } from "../const/tipi-documento";
import { Persona } from "./persona";
import { Pratica } from "./pratica";

export class Documento {
    id: number = 0;
    filename: string = "";
    dataCaricamento: string = "";
    oraCaricamento: string = "";
    tipoDocumento: TipiDocumento = TipiDocumento.AALELO_AGENZIA;
    personaId: number | null = null;
    persona: Persona | null = null;
    praticaId: number | null = null;
    pratica: Pratica | null = null;
}