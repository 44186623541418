import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SpinnerService } from './spinner.service';
import { RegistrazioneSocieta } from '../models/registrazione-societa';
import { AnagraficaUtente } from '../models/anagrafica-utente';
import { Albo } from '../const/user-const';
import { StringModel } from '../models/string-model';
import { UserRoles } from '../const/user-roles';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public token: string = "";
  public refresh_token: string = "";
  public user: AnagraficaUtente = new AnagraficaUtente();
  public isAdmin = false;
  public isTeamLeader = false;
  public isTecnico = false;
  public isTecnicoInterno = false;
  public isConsulente = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private spinner: SpinnerService) { }

  load(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.token = localStorage.getItem("token") ?? "";
      this.refresh_token = localStorage.getItem("refresh_token") ?? "";
      if(this.token.length > 0) {
        this.getUser().then(() => {
          resolve(null);
        }).catch(() => {
          resolve(null);
        });
      } else {
        resolve(null);
      }
    });
  }

  login(email: string, password: string) {
    return new Promise<string>((resolve, reject) => {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      this.token = "";
      this.refresh_token = "";
      let body = { username: email, password: password }
      this.http.post(environment.API_URL + "authenticate/login", body, {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
        })
      }).subscribe({next: (data: any) => {
        localStorage.setItem("token", data.Token);
        localStorage.setItem("refresh_token", data.RefreshToken);
        this.token = data.Token;
        this.refresh_token = data.RefreshToken;
        this.getUser().then(() => {
          resolve("");
        }).catch(() => {
          reject();
        });
      }, error: (error: HttpErrorResponse) => {
        reject(error.error);
      }});
    })
  }

  refreshToken() {
    return new Observable<boolean>((observer) => {
      let body = {AccessToken: this.token, RefreshToken: this.refresh_token};
      let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
        })
      }; 
      var refreshSub = this.http.post(environment.API_URL + "authenticate/refresh-token", body, httpOptions).subscribe({next: (res: any) => {
        localStorage.setItem("token", res.AccessToken);
        localStorage.setItem("refresh_token", res.RefreshToken);
        this.token = res.AccessToken;
        this.refresh_token = res.RefreshToken;
        refreshSub.unsubscribe();
        observer.next(true);
      }, error: (err: any) => {
        refreshSub.unsubscribe();
        observer.error();
      }});
    });
  }

  registrazioneSocieta(model: RegistrazioneSocieta) {
    return new Promise<string>((resolve, reject) => {
      var sub = this.http.post(environment.API_URL + "authenticate/register-societa", model).subscribe({
        next: (res: any) => {
          sub.unsubscribe();
          resolve("");
        }, error: (err: HttpErrorResponse) => {
          reject(err.error);
          sub.unsubscribe();
        }
      });
    });
  }

  registrazioneTecnico(model: AnagraficaUtente) {
    return new Promise<string>((resolve, reject) => {
      model.tecnico_albo = Number(model.tecnico_albo);
      var sub = this.http.post(environment.API_URL + "authenticate/register-tecnico", model).subscribe({
        next: (res: any) => {
          resolve("");
          sub.unsubscribe();
        }, error: (err: HttpErrorResponse) => {
          reject(err.error);
          sub.unsubscribe();
        }
      });
    });
  }

  logout(navigate: boolean = true) {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    this.token = "";
    this.refresh_token = "";
    if(navigate) {
      this.router.navigate(["/"]);
    }
  }

  getUser() {
    return new Promise<any>((resolve, reject) => {
      this.http.get(environment.API_URL + "authenticate/user").subscribe({next : (res) => {
        this.user = res as AnagraficaUtente;
        this.isAdmin = this.user?.ruolo == UserRoles.Admin;
        this.isTeamLeader = this.user?.ruolo == UserRoles.TeamLeader;
        this.isTecnico = this.user?.ruolo == UserRoles.Tecnico;
        if(this.isTecnico) {
          this.isTecnicoInterno = this.user?.tecnico_interno;
        }
        this.isConsulente = this.user?.ruolo == UserRoles.Consulente;
        resolve(null);
      }, error: (err: any) => {
        this.logout();
        reject();
      }});
    });
  }

  async checkAuth() {
    return new Promise<any>((resolve, reject) => {
      var token = localStorage.getItem("token");
      if(token = null) {
        reject();
      }
      this.http.get(environment.API_URL + "authenticate/check").subscribe({next: () => {
        resolve(null);
      }, error: (err: any) => {
        reject();
      }})
    });
  }

  forgotPassword(email: string) {
    return new Promise<any>((resolve, reject) => {
      this.http.get(environment.API_URL + "authenticate/forgot?email=" + encodeURIComponent(email)).subscribe({next: () => {
        resolve(null);
      }, error: (err: any) => {
        reject();
      }})
    });
  }

  resetPassword(meta: string, password: string) {
    return new Promise<any>((resolve, reject) => {
      var body = {Meta: meta, NewPassword: password};
      this.http.post(environment.API_URL + "authenticate/forgot", body).subscribe({next: () => {
        resolve(null);
      }, error: (err: any) => {
        reject();
      }})
    });
  }

  accettaCondizioni(userId: string) {
    return new Promise<any>((resolve, reject) => {
      if(userId == null) {
        reject();
      }
      var body = new StringModel();
      body.value = userId;
      this.http.post(environment.API_URL + "authenticate/condizioni", body).subscribe({next: () => {
        resolve(null);
      }, error: (err: any) => {
        reject();
      }})
    });
  }

  inviaMailConferma(email: string) {
    return new Promise<any>((resolve, reject) => {
      if(email == null) {
        reject();
      }
      this.http.get(environment.API_URL + "authenticate/mail-conferma/" + encodeURIComponent(email)).subscribe({next: () => {
        resolve(null);
      }, error: (err: any) => {
        reject();
      }})
    });
  }

  confermaMail(userId: string) {
    return new Promise<any>((resolve, reject) => {
      if(userId == null) {
        reject();
      }
      var body = new StringModel();
      body.value = userId;
      this.http.post(environment.API_URL + "authenticate/mail-conferma", body).subscribe({next: () => {
        resolve(null);
      }, error: (err: any) => {
        reject();
      }})
    });
  }

  validatePassword(password: string) : boolean {
    return /^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/.test(password);
  }
}
