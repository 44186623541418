import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class RefreshInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject = new BehaviorSubject(null);

  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(this.addAuthToken(request)).pipe(
      catchError((requestError: HttpErrorResponse) => {
        if(requestError.url) {
          if (requestError && requestError.status === 401 && !requestError.url.includes("login")) {
            if (this.refreshTokenInProgress) {
              return this.refreshTokenSubject.pipe(
                take(1),
                switchMap(() => next.handle(this.addAuthToken(request)))
              );
            } else {
              this.refreshTokenInProgress = true;
              this.refreshTokenSubject.next(null);

              return this.authService.refreshToken().pipe(
                switchMap(() => {
                  this.refreshTokenSubject.next(null);
                  return next.handle(this.addAuthToken(request));
                }),
                finalize(() => (this.refreshTokenInProgress = false)),
                catchError((err: any, caught: Observable<HttpEvent<any>>) => {
                  this.authService.logout();
                  return throwError(() => requestError);
                })
              );
            }
          } else {
            return throwError(() => requestError);
          }
        } else {
          return throwError(() => requestError);
        }
      })
    );
  }

  addAuthToken(request: HttpRequest<any>) {

    if (!this.authService.token) {
      return request;
    }

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authService.token}`,
      },
    });
  }
}