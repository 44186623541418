import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import * as Sentry from "@sentry/angular-ivy";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { PraticheComponent } from './pages/pratiche/pratiche.component';
import { ClarityModule } from "@clr/angular";
import { FormsModule } from '@angular/forms';
import { LoadingComponent } from './components/loading/loading.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { RefreshInterceptor } from './helpers/refresh_interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { RegistrazioneAgenziaComponent } from './pages/registrazione-agenzia/registrazione-agenzia.component';
import { RegistrazioneTecnicoComponent } from './pages/registrazione-tecnico/registrazione-tecnico.component';
import { ThankyouRegistrazioneComponent } from './pages/thankyou-registrazione/thankyou-registrazione.component';
import { AnagraficaSocietaComponent } from './pages/anagrafica-societa/anagrafica-societa.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NoThousandSeparatorPipe } from './helpers/no_thousand_separator';
import { PraticaFormComponent } from './pages/pratica-form/pratica-form.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AssistenzaComponent } from './pages/assistenza/assistenza.component';
import { UtentiComponent } from './pages/utenti/utenti.component';
import { UtenteComponent } from './pages/utente/utente.component';
import { ConfermaMailComponent } from './pages/conferma-mail/conferma-mail.component';
import { AgenzieComponent } from './pages/admin/agenzie/agenzie.component';
import { TecniciComponent } from './pages/admin/tecnici/tecnici.component';
import { AnagraficaTecnicoComponent } from './pages/anagrafica-tecnico/anagrafica-tecnico.component';
import { DownloadComponent } from './pages/download/download.component';
import { LpTemplateComponent } from './components/lp-template/lp-template.component';
import { StatsComponent } from './pages/stats/stats.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { TeamleadersComponent } from './pages/teamleaders/teamleaders.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AgenzieSelectComponent } from './pages/agenzie-select/agenzie-select.component';
import { Router } from '@angular/router';
import { RefreshComponent } from './pages/refresh/refresh.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PraticheComponent,
    LoadingComponent,
    ResetPasswordComponent,
    RegistrazioneAgenziaComponent,
    RegistrazioneTecnicoComponent,
    ThankyouRegistrazioneComponent,
    AnagraficaSocietaComponent,
    NoThousandSeparatorPipe,
    PraticaFormComponent,
    AssistenzaComponent,
    UtentiComponent,
    UtenteComponent,
    ConfermaMailComponent,
    AgenzieComponent,
    TecniciComponent,
    AnagraficaTecnicoComponent,
    DownloadComponent,
    LpTemplateComponent,
    StatsComponent,
    TeamleadersComponent,
    AgenzieSelectComponent,
    RefreshComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ClarityModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({positionClass: "toast-bottom-center"}),
    FontAwesomeModule,
    NgSelectModule,
    NgApexchartsModule,
    NgxPaginationModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'it' },
    AuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: (as: AuthService) => function() {return as.load()},
      deps: [AuthService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }
