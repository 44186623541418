import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { PraticheComponent } from './pages/pratiche/pratiche.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { RegistrazioneTecnicoComponent } from './pages/registrazione-tecnico/registrazione-tecnico.component';
import { RegistrazioneAgenziaComponent } from './pages/registrazione-agenzia/registrazione-agenzia.component';
import { ThankyouRegistrazioneComponent } from './pages/thankyou-registrazione/thankyou-registrazione.component';
import { AnagraficaSocietaComponent } from './pages/anagrafica-societa/anagrafica-societa.component';
import { PraticaFormComponent } from './pages/pratica-form/pratica-form.component';
import { AssistenzaComponent } from './pages/assistenza/assistenza.component';
import { UtentiComponent } from './pages/utenti/utenti.component';
import { UtenteComponent } from './pages/utente/utente.component';
import { ConfermaMailComponent } from './pages/conferma-mail/conferma-mail.component';
import { PendingChangesGuard } from './helpers/pending-changes-guard';
import { AgenzieComponent } from './pages/admin/agenzie/agenzie.component';
import { UserRoles } from './const/user-roles';
import { TecniciComponent } from './pages/admin/tecnici/tecnici.component';
import { AnagraficaTecnicoComponent } from './pages/anagrafica-tecnico/anagrafica-tecnico.component';
import { DownloadComponent } from './pages/download/download.component';
import { StatsComponent } from './pages/stats/stats.component';
import { TeamleadersComponent } from './pages/teamleaders/teamleaders.component';
import { AgenzieSelectComponent } from './pages/agenzie-select/agenzie-select.component';
import { RefreshComponent } from './pages/refresh/refresh.component';

const routes: Routes = [
  { path: '', component: LoginComponent},
  { path: 'refresh', component: RefreshComponent},
  { path: 'reset-password', component: ResetPasswordComponent},
  { path: 'registrazione-tecnico', component: RegistrazioneTecnicoComponent},
  { path: 'registrazione-agenzia', component: RegistrazioneAgenziaComponent},
  { path: 'thankyou-registrazione', component: ThankyouRegistrazioneComponent},
  { path: 'pratiche', component: PraticheComponent, canActivate: [AuthGuardService]},
  { path: 'anagrafica-societa', component: AnagraficaSocietaComponent, canActivate: [AuthGuardService], data: {roles: [UserRoles.TitolareAgenzia, UserRoles.ResponsabileAgenzia]}},
  { path: 'anagrafica-societa/:id', component: AnagraficaSocietaComponent, canActivate: [AuthGuardService], data: {roles: [UserRoles.Admin, UserRoles.TeamLeader]}},
  { path: 'pratica-form/:id', component: PraticaFormComponent, canActivate: [AuthGuardService], canDeactivate: [PendingChangesGuard]},
  { path: 'pratica-form', component: PraticaFormComponent, canActivate: [AuthGuardService], canDeactivate: [PendingChangesGuard]},
  { path: 'utente/:id', component: UtenteComponent, canActivate: [AuthGuardService]},
  { path: 'utente', component: UtenteComponent, canActivate: [AuthGuardService]},
  { path: 'utente/societa/:societaId', component: UtenteComponent, canActivate: [AuthGuardService], data: { roles: [UserRoles.Admin, UserRoles.TeamLeader]}},
  { path: 'utenti', component: UtentiComponent, canActivate: [AuthGuardService]},
  { path: 'assistenza', component: AssistenzaComponent},
  { path: 'conferma-mail', component: ConfermaMailComponent},
  { path: 'admin/agenzie', component: AgenzieComponent, canActivate: [AuthGuardService], data: { roles: [UserRoles.Admin]}},
  { path: 'admin/tecnici', component: TecniciComponent, canActivate: [AuthGuardService], data: { roles: [UserRoles.Admin]}},
  { path: 'admin/teamleaders', component: TeamleadersComponent, canActivate: [AuthGuardService], data: { roles: [UserRoles.Admin]}},
  { path: 'anagrafica-tecnico/:id', component: AnagraficaTecnicoComponent, canActivate: [AuthGuardService], data: {roles: [UserRoles.Admin]}},
  { path: 'anagrafica-tecnico', component: AnagraficaTecnicoComponent, canActivate: [AuthGuardService], data: {roles: [UserRoles.Admin, UserRoles.Tecnico]}},
  { path: 'download/:token', component: DownloadComponent},
  { path: 'stats', component: StatsComponent, canActivate: [AuthGuardService]},
  { path: 'agenzie-select', component: AgenzieSelectComponent, canActivate: [AuthGuardService], data: {roles: [UserRoles.TeamLeader]}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
