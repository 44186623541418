<div class="page-container container-large">
    <h3 class="section-title">Tecnici</h3>

    <form>
        <div class="cerca pt-2 pr-2 pb-0 pl-2">
            <div class="clr-row input-block">
                <div class="clr-col-md">
                    <clr-input-container>
                        <label>Provincia</label>
                        <input clrInput type="text" [(ngModel)]="cercaModel.provincia" name="provincia"/>
                    </clr-input-container>
                </div>
                <div class="clr-col-md">
                    <clr-input-container>
                        <label>Località</label>
                        <input clrInput type="text" [(ngModel)]="cercaModel.localita" name="localita"/>
                    </clr-input-container>
                </div>
                <div class="clr-col-md">
                    <clr-input-container>
                        <label>Nome e cognome</label>
                        <input clrInput type="text" [(ngModel)]="cercaModel.nome" name="nome"/>
                    </clr-input-container>
                </div>
            </div>
            <div class="text-right mt-1">
                <button class="btn btn-primary" type="submit" (click)="cerca()">Cerca</button>
            </div>
        </div>
    </form>
</div>

<div class="page-container container-large pl-3 pr-3 pr-3 mt-0 pt-0">
    <div class="custom-tabs-switch">
        <p [ngClass]="{'active': showInterni}" (click)="switchInterni()">Interni</p>
        <p [ngClass]="{'active': showEsterni}" (click)="switchEsterni()">Esterni</p>
    </div>

    <p>
        <fa-icon class="yellow" [icon]="faTriangleExclamation"></fa-icon> = Da approvare
        <button class="btn btn-primary" style="float: right;" (click)="crea()" *ngIf="showInterni">Nuovo tecnico interno</button>
    </p>
    <table class="table pratiche-table">
        <thead>
            <th></th>
            <th>Nome e cognome</th>
            <th>Città</th>
            <th>Provincia</th>
            <th>Telefono</th>
            <th>Email</th>
            <th>Ruolo</th>
            <th></th>
        </thead>
        <tbody>
            <ng-container *ngFor="let tec of tecnici; index as i">
                <tr *ngIf="(tec.tecnico_interno || tec.tecnico_esterno_spa) == showInterni" [ngClass]="{'disabilitato': !tec.active}">
                    <td><fa-icon class="yellow" [icon]="faTriangleExclamation" *ngIf="!tec.accettatoDaStudio"></fa-icon></td>
                    <td>{{tec.nome}}</td>
                    <td>{{tec.tecnico_sede_localita}}</td>
                    <td>{{tec.tecnico_sede_provincia}}</td>
                    <td>{{tec.tecnico_sede_telefono}}</td>
                    <td>{{tec.email}}</td>
                    <td>{{ showEsterni ? "Tecnico Esterno" : (tec.tecnico_interno ? "Tecnico Interno" : "Tecnico Esterno (SPA)") }}</td>
                    <td>
                        <div class="btn-group btn-primary">
                            <button class="btn btn-primary btn-icon" (click)="edit(tec)"><fa-icon [icon]="faPenToSquare"></fa-icon></button>
                        </div>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>