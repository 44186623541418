import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  public stack: number = 0;

  constructor() { }

  show() {
    setTimeout(() => {
      this.stack++;
    }, 0);
  }

  dismiss() {
    setTimeout(() => {
      if(this.stack > 0) {
        this.stack--;
      }
    }, 0);
  }
}
