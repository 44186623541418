<app-lp-template>
    <div class="px-3 pb-3 pt-2 input-block">
        <form (submit)="reset()">
            <h2 class="text-center mt-0">Reimposta password</h2>
            <p class="text-center mt-0">Inserisci qui sotto la tua nuova password</p>
            <clr-password-container>
                <label>Password</label>
                <input clrPassword [(ngModel)]="nuovaPassword" name="nuovaPassword" tabindex="0" />
            </clr-password-container>
            <clr-password-container>
                <label>Conferma password</label>
                <input clrPassword [(ngModel)]="confermaPassword" name="confermaPassword" tabindex="0" />
            </clr-password-container>
            <button class="btn btn-primary btn-block mt-2" type="submit">
                <app-loading *ngIf="loading"></app-loading>
                <span *ngIf="!loading">INVIA</span>
            </button>
        </form>
    </div>
</app-lp-template>