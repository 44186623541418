import { ApplicationRef, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Albo } from 'src/app/const/user-const';
import { UserRoles } from 'src/app/const/user-roles';
import { AnagraficaUtente } from 'src/app/models/anagrafica-utente';
import { AuthService } from 'src/app/services/auth.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UtentiService } from 'src/app/services/utenti.service';
import { Utils } from 'src/app/utils';

@Component({
  selector: 'app-anagrafica-tecnico',
  templateUrl: './anagrafica-tecnico.component.html',
  styleUrls: ['./anagrafica-tecnico.component.scss']
})
export class AnagraficaTecnicoComponent {
  model = new AnagraficaUtente();
  confermaPassword = "";
  spa = false;
  edit = false;
  albo = Albo;
  ruolo = "interno";

  constructor(private utentiSrv: UtentiService, private toastr: ToastrService, private spinner: SpinnerService, public auth: AuthService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    var id = this.route.snapshot.paramMap.get('id');
    var create = this.route.snapshot.paramMap.get('new');
    if(id != null) {
      if(!this.auth.isAdmin) {
        this.router.navigate(["/"]);
      }
      this.edit = true;
      this.spinner.show();
      this.utentiSrv.getUtenteById(id).then((res) => {
        this.model = res;
        if(!res.tecnico_interno) {
          if(res.tecnico_esterno_spa) {
            this.ruolo = "spa";
          } else {
            this.ruolo = "esterno";
          }
        } else {
          this.ruolo = "interno";
        }
        this.spinner.dismiss();
      }).catch((err) => {
        this.spinner.dismiss();
        this.toastr.error("Errore");
      });
    } else {
      if(create == "true") {
        this.edit = false;
      } else {
        this.edit = true;
        this.model = this.auth.user;
      }
    }
  }

  save() {
    if(!this.validate()) {
      return;
    }
    this.model.tecnico_interno = this.ruolo == "interno";
    this.model.tecnico_esterno_spa = this.ruolo == "spa";
    if(this.edit) {
      this.spinner.show();
      this.utentiSrv.editUtente(this.model).then((res) => {
        this.toastr.success("Utente salvato correttmanete");
        this.model = res;
        this.confermaPassword = "";
        this.spinner.dismiss();
      }).catch((err) => {
        var errore = "Errore sconosciuto";
        switch(err) {
          case "error.user.already_exists":
            errore = "Esiste già un utente con questa mail";
            break;
          case "error.user.password_invalid":
            errore = "La password non è valida";
            break;
          case "error.user.email_invalid":
            errore = "Inserisci un indirizzo email valido";
            break;
          case "save_error":
            errore = "C'è stato un problema con il salvataggio, contattaci a info@praticaok.com";
            break;
        }
        this.toastr.error(errore);
        this.spinner.dismiss();
      });
    } else {
      if(!this.auth.isAdmin) {
        return;
      }
      this.spinner.show();
      this.utentiSrv.createTecnicoInterno(this.model).then((res) => {
        this.toastr.success("Tecnico interno creato correttmanete");
        this.router.navigate(["admin/tecnici"]);
        this.spinner.dismiss();
      }).catch((err) => {
        var errore = "Errore sconosciuto";
        switch(err) {
          case "error.user.already_exists":
            errore = "Esiste già un utente con questa mail";
            break;
          case "error.user.password_invalid":
            errore = "La password non è valida";
            break;
          case "error.user.email_invalid":
            errore = "Inserisci un indirizzo email valido";
            break;
          case "save_error":
            errore = "C'è stato un problema con il salvataggio, contattaci a info@praticaok.com";
            break;
        }
        this.toastr.error(errore);
        this.spinner.dismiss();
      });
    }
  }


  validate() : boolean {
    if(Utils.parseDataString(this.model.tecnico_dataNascita) == null && this.ruolo != "interno") {
      this.toastr.error("Inserisci una data di nascita valida");
      return false
    }
    if((this.model.password.trim().length > 0 || !this.edit) && !this.auth.validatePassword(this.model.password)) {
      this.toastr.error("La password deve essere lunga almeno 8 caratteri e contenere almeno una lettera minuscola, una minuscola e un numero");
      return false;
    }
    if((this.model.password.trim().length > 0 || !this.edit) && this.model.password != this.confermaPassword) {
      this.toastr.error("Le due password devono coincidere");
      return false;
    }
    return true;
  }
}
